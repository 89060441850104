import React, { useEffect, useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import * as orderActions from "../../../state/order/orderActions";
import * as commonActions from "../../../state/common/commonActions";
import BarcodeReader from "react-barcode-reader";
import ProductService from "../../../api/ProductAPI";
import { n_format } from "../../../utils/common";

function AddNewProduct({ show, toggle }) {
  const [quantity, setQuantity] = useState(1);
  const [found, setFound] = useState(false);
  const [item, setItem] = useState({});
  const [usingScanner, setUsingScanner] = useState(false);
  // const [usingSelected, setUsingSelected] = useState(false)
  const order = useSelector(state => state.order);
  const common = useSelector((state) => state.common);

  
  const [price, setPrice] = useState(order?.selected_product_item?.price);
  const [barcode, setBarcode] = useState(order?.selected_product_item?.barcode);

  const fillBarcode = (e) => setBarcode(e.target.value);
  const fillQuantity = (e) => setQuantity(e.target.value.replace('.',''));
  const fillPrice = (e) => setPrice(e.target.value);

  const dispatch = useDispatch();

  const onOk = () => {
    if(barcode && quantity && (parseInt(quantity) > 0) && (price!="")){
      const product = {
        ...item,
        barcode,
        cost: price,
        description:item?.description ?? barcode,
        id: item?.id ?? barcode,
        item_code: item?.item_code ?? barcode,
        name_en: item?.name_en ?? barcode,
        price,
        quantity:parseInt(quantity)
      };
      dispatch(orderActions.setOrderProducts(product));
      back();
    }
  };

  const back = () => {
    if(barcode !== order?.selected_product_item?.barcode){
      
      setBarcode("0");
      setPrice("0.00");
      setFound(false);
     
    }
    dispatch(commonActions?.setActiveField(null))
    if(common?.showkeyboard)
    dispatch(commonActions.toggleKeyboard());
    toggle();
  }


  useEffect(() => {
    if(order?.products?.length > 0)
    {}
    else{  
      setBarcode("0");
      setPrice("0.00");
      setFound(false);
    }
  }, [order?.products])
  

  useEffect(() => {
    getProductPrice();
  }, [barcode]);


  useEffect(() => {

    setPrice(order?.selected_product_item?.price);
    setBarcode(order?.selected_product_item?.barcode);
    setQuantity(order?.selected_product_item?.quantity);
    showKeyboard();
    
  }, [show]);

  useEffect(() => {
    setBarcode(order?.selected_product_item?.barcode );
    setQuantity(order?.selected_product_item?.quantity)
    setPrice(order?.selected_product_item?.price)
    setUsingScanner(false)
  }, [order.selected_product_item]);
  


  useEffect(() => {
    if (common?.activeField) {
      if (common?.activeField === "ANPB") {
        setBarcode(common?.text);
        getProductPrice();
      } else if (common?.activeField === "ANPQ") {
        setQuantity(common?.text.replace('.',''))
      }
      else if(common?.activeField === "ANPP"){
        setPrice(common?.text)
      }
    } else {
    }
    setUsingScanner(false)
  }, [common.text]);

  const showKeyboard = () => {
    if(show)
    dispatch(commonActions?.setActiveField("ANPB"))
    if(!common?.showkeyboard && show)
    dispatch(commonActions.toggleKeyboard());

    // commonActions?.setText(order?.selected_product_item?.barcode)
  };

  const getProductPrice = async () => {
    const data = await ProductService.searchProducts({barcode});
    if (data?.data.length > 0) {
      setFound(true)
      setItem(data.data[0])
      setPrice(n_format(data.data[0]['price']));
    }else{
      if(usingScanner){
        setBarcode("")
        dispatch(commonActions?.emptyText())
      }
      if(barcode !== order?.selected_product_item?.barcode)
      setFound(false);
      else
      setFound(true)
    }
  }

  
  const handleScan = async (code) => {
    setUsingScanner(true)
    setBarcode(code);
  };
  const handleError = (err) => {
    console.error(err);
  };

  const emptyBarcode =() => {
    setBarcode("");
    dispatch(commonActions?.emptyText())
  };
  const emptyPrice =() => {
    setPrice("")
    dispatch(commonActions?.emptyText())
  };
  const emptyQuantity =() => {
    setQuantity("")
    dispatch(commonActions?.emptyText())
  };

  const renderModal = () => {
    return (
      <div className="column">
        {found || !barcode ?null :<p className="text-1">Not Found</p>}
        <div className="row justify-between">
        <BarcodeReader onError={handleError} onScan={handleScan} />
          <div className="text-1" style={{ fontSize: 22 }}>
            Barcode
          </div>
          <input
            type="text"
            value={barcode}
            onChange={fillBarcode}
            onClick={emptyBarcode}
            onFocus={()=>dispatch(commonActions?.setActiveField("ANPB"))}
            className={`numpad-input-1 ${common?.activeField == 'ANPB'?'active-field':''}`}
          />
        </div>
        <div className="row justify-between">
          <div className="text-1" style={{ fontSize: 22 }}>
            Qty
          </div>
          <input
            type="text"
            value={quantity?.toString()?.replace('.','')}
            onChange={fillQuantity}
            onClick={emptyQuantity}
            onFocus={()=>dispatch(commonActions?.setActiveField("ANPQ"))}
            className={`numpad-input-1 ${common?.activeField == 'ANPQ' ? 'active-field' : '' }`}

          />
        </div>
        <div className="row justify-between">
          <div className="text-1" style={{ fontSize: 22 }}>
            Price
          </div>
          <input
            type="text"
            value={price}
            onChange={fillPrice}
            onClick={emptyPrice}
            onFocus={()=>dispatch(commonActions?.setActiveField("ANPP"))}
            className={`numpad-input-1 ${common?.activeField == 'ANPP'?'active-field':''}`}
          />
        </div>
      </div>
    );
  };
  return (
    <>
    <CommonModal
      style={{zIndex:1}}
      children={renderModal}
      show={show}
      onOk={ found ? onOk : () => {}}
      title="Add New"
      toggleModal={back}
    />
    </>
  );
}

export default AddNewProduct;
