import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonModal from "../../common/components/CommonModal";
import * as commonActions from "../../state/common/commonActions";
import * as orderActions from "../../state/order/orderActions";
import * as customerActions from "../../state/customer/customerActions";
import * as userActions from "../../state/user/userActions";
import * as suspendActions from "../../state/suspend/suspendActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InvoiceService from "../../api/InvoiceAPI";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import FinalTable from "./components/FinalTable";
import { number_format, n_format } from "../../utils/common";

function SignOffPageContainer() {
  // Confirm Logout
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const toggleConfirmLogout = () => setShowConfirmLogout(!showConfirmLogout);
  
  const [amount, setAmount] = useState(0);
  const [ramount, setRAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [points, setPoints] = useState(0);
  const [redeemedPoints, setRedeemedPoints] = useState(0)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [invoices, setInvoices] = useState([]);
  const [returnInvoices, setReturnInvoices] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("Search")

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);

  const onOkConfirmLogout = () => {
    dispatch(commonActions.trashOff());
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    dispatch(userActions.emptyUser());
    dispatch(suspendActions.emptySuspend());
    history.push("/");
  };
 

  // useEffect(() => {
  //   getSignOffData();
  // }, [startTime, endTime, startDate]);

  useEffect(() => {
    calculateData();
  }, [invoices])

  const getSignOffData = async () => {
    setIsLoading(true);
    setPageTitle("Searching...")
    console.log(startTime);
    console.log(endTime);
    // console.log( endTime.toLocaleTimeString(),);
    try {
      
      const data2 = await InvoiceService.searchInvoices(
        "",
        startDate.toLocaleDateString("en-CA"),
        endDate.toLocaleDateString("en-CA"),
        startTime,
        endTime,
        user?.data?.branch?.id,
        user?.data?.id,
        null,
        1,
        2000
        );
        const data = data2.data.invoices;
        const r_data = data2.data.return_invoices;
        console.log(data2);
        if (data.data.length > 0 || r_data.data.length > 0 ) {
          setReturnInvoices(r_data.data);
          setInvoices(data.data);
          setIsLoading(false);
          setPageTitle("Search");
          // calculateData();
          
        } else {
          setInvoices([]);
          setReturnInvoices([]);
          setIsLoading(false);
          setPageTitle("Search")

        }
      }
       catch (error) {
         console.log(error);
        setIsLoading(false)
        setPageTitle("Search")
      }
      };
      
  const calculateData = () => {
    let points ="0";
    let redeem ="0";
    let r_points ="0";
    let r_redeem ="0";
    if(invoices?.length > 0){

      const amount = invoices?.map((e) => {
        const orderSum = e?.order?.map(e2 => {
          return (e2?.quantity * e2?.price) - (e2?.quantity * e2?.price * e2?.discount_percent /100);
        })?.reduce((a2, b2) => +a2 + +b2);
        return orderSum;
      })?.reduce((a, b) => +a + +b);

      const discount = invoices?.map((e) => {
        const orderSum = number_format(e?.order?.map(e2 => {
          return number_format(e2?.quantity * e2?.price)
        })?.reduce((a2, b2) => +a2 + +b2));
        return number_format((orderSum * e?.total_discount )/100);
      })?.reduce((a, b) => +a + +b);


      const pointsArray = invoices?.filter(e => e?.customer_id)?.map((e) => e?.order_point);

      const redeemArray = invoices?.filter(e => e?.customer_id)?.map((e) => e?.redeem_amount);

      if(pointsArray.length > 0)
      points = number_format(pointsArray?.reduce((a, b) =>  (+a + +b)));

      if(redeemArray.length > 0)
      redeem = number_format(redeemArray?.reduce((a, b) => +a + +b));

      setAmount(amount);
      setDiscount(discount);
      setPoints(points);
      setRedeemedPoints(redeem);
      console.log(124);

      if(returnInvoices.length > 0){
        console.log(1245);

        const r_amount = returnInvoices?.map((e) => {
          const orderSum = e?.order?.map(e2 => {
            return (e2?.quantity * e2?.price) - (e2?.quantity * e2?.price * e2?.discount_percent /100);
          })?.reduce((a2, b2) => +a2 + +b2);
          return orderSum;
        })?.reduce((a, b) => +a + +b);

        // console.log(amount);
        // console.log(r_amount);
  
        const r_discount = returnInvoices?.map((e) => {
          const orderSum = e?.order?.map(e2 => {
            return number_format(e2?.quantity * e2?.price)
          })?.reduce((a2, b2) => +a2 + +b2);
          return number_format((orderSum * e?.total_discount )/100);
        })?.reduce((a, b) => +a + +b);
  
  
        const r_pointsArray = returnInvoices?.filter(e => e?.customer_id)?.map((e) => e?.order_point);
  
        const r_redeemArray = returnInvoices?.filter(e => e?.customer_id)?.map((e) => e?.redeem_amount);
  
        if(r_pointsArray.length > 0)
        r_points = number_format(r_pointsArray?.reduce((a, b) => +a + +b));
  
        if(r_redeemArray.length > 0)
        r_redeem = number_format(r_redeemArray?.reduce((a, b) => +a + +b));
  
        setAmount(amount - r_amount);
        setRAmount(r_amount)
        // setAmount(amount);
        setDiscount(discount - r_discount);
        setPoints(points - r_points);
        setRedeemedPoints(redeem - r_redeem);
      }


    }else{
      setAmount(0);
      setRAmount(0)
      setDiscount(0);
      setPoints(0);
      setRedeemedPoints(0)
    }
  }

  return (
    <div className="home-container  bg-10 w-100">
      <div className="modal-header-lg row bg-1 align-center justify-center">
        <h3 style={{ color: "var(--white)" }}>Sales Report</h3>
      </div>
      <div className="modal-actions-lg row justify-center align-center">
        <Link to="/operation" className="modal-btn-lg row justify-center align-center bg-1 text-1">
        <FaArrowLeft color="#fff" /> &nbsp; Back 
        </Link>
      </div>

      <div className="row justify-left align-center mt-2 label">
        <p>Start Date:</p>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showMonthYearDropdown
          dateFormat="MMMM d, yyyy "
          className="datepicker"
        />
         <p>End Date:</p>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          showMonthYearDropdown
          dateFormat="MMMM d, yyyy "
          className="datepicker"
          minDate={startDate} 
        />
        &ensp;
        <p>Start Time:</p>
        <input
        type="time"
          selected={startTime}
          onChange={(date) => setStartTime(date.target.value)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={1}
          timeCaption="Time"
          dateFormat="h:mm aa"
          className="datepicker"
        />
        &ensp;
         <p>End Time:</p>
        <input
        type="time"
          selected={endTime}
          onChange={(date) => setEndTime(date.target.value)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={1}
          timeCaption="Time"
         
          dateFormat="h:mm aa"
          className="datepicker"
        />
        &ensp;
       
      </div>
      
      <br/>
      <div
        onClick={getSignOffData}
        style={{ display: "inline-block" }}
        className={`modal-btn-lg ${isLoading?"bg-3":"bg-1"} text-1 mt-1 mb-1`}
      >
        {pageTitle} <FaSearch color="#fff" />
      </div>
      <div
        style={{ display: "inline-block" }}
        className="modal-btn-lg bg-6 text-1 mt-1 mb-1"
      >
        User Name : {user?.data?.name}
      </div>
     <br/>
     <br/>
     <FinalTable
        data={{amount,ramount,discount,points,redeemedPoints,startDate:startDate.toLocaleDateString("en-CA"),endDate:endDate.toLocaleDateString("en-CA")}}
      />
      {/* <div
        style={{ display: "inline-block" }}
        className="modal-btn-lg bg-1 text-1 mt-1 mb-1"
      >
        Total Sales ($): ${n_format(amount)}
      </div>
      <div
        style={{ display: "inline-block" }}
        className="modal-btn-lg bg-1 text-1 mt-1 mb-1"
      >
        Total Discount on Orders ($) : ${n_format(discount)}
      </div>
      <div
        style={{ display: "inline-block" }}
        className="modal-btn-lg bg-6 text-1 mt-1 mb-1"
      >
        Total Net Sales ($) : ${n_format(amount - discount)}
      </div>
      <br/>
      <br/>
      <div
        style={{ display: "inline-block" }}
        className="modal-btn-lg bg-7 text-1 mt-1 mb-1"
      >
        Total Points Generated ($): ${n_format(points)}
      </div>
      <div
        style={{ display: "inline-block" }}
        className="modal-btn-lg bg-7 text-1 mt-1 mb-1"
      >
        Total Points Redeemed ($): ${n_format(redeemedPoints)}
      </div> */}
      <CommonModal
        children={() => {}}
        show={showConfirmLogout}
        onOk={onOkConfirmLogout}
        title="Confirm Logout ?"
        toggleModal={toggleConfirmLogout}
      />
    </div>
  );
}

export default SignOffPageContainer;
