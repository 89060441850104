
export const addOfflineInvoice = (payload) => {

  return {
    type: "ADD_OFFLINE_INVOICE",
    payload,
  };
};

export const deleteOfflineInvoice = (payload) => {

  return {
    type: "DELETE_OFFLINE_INVOICE",
    payload,
  };
};

export const emptyOfflineInvoices = (payload) => {
  return {
    type: "EMPTY_OFFLINE_INVOICES",
    payload,
  };
};


export const addCheckInvoice = (payload) => {

  return {
    type: "ADD_CHECK_INVOICE",
    payload,
  };
};

export const deleteCheckInvoice = (payload) => {

  return {
    type: "DELETE_CHECK_INVOICE",
    payload,
  };
};

export const emptyCheckInvoices = (payload) => {
  return {
    type: "EMPTY_CHECK_INVOICES",
    payload,
  };
};



export const addOfflineCustomer = (payload) => {

  return {
    type: "ADD_OFFLINE_CUSTOMER",
    payload,
  };
};

export const deleteOfflineCustomer = (payload) => {

  return {
    type: "DELETE_OFFLINE_CUSTOMER",
    payload,
  };
};



export const emptyOfflineCustomers = (payload) => {
  return {
    type: "EMPTY_OFFLINE_CUSTOMERS",
    payload,
  };
};


export const emptyOffline = (payload) => {
  return {
    type: "EMPTY_OFFLINE",
    payload,
  };
};
