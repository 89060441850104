import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../../../common/components/CommonModal";
import * as suspendActions from "../../../state/suspend/suspendActions";


function SuspendModal({ show, toggle}) {
    const suspend = useSelector(state => state.suspend)
    const dispatch = useDispatch();
  const renderModal = () => {
    return (
      <div className="w-100 column">
        {suspend?.data?.map((e, i) => {
          return (
            <div
              key={i}
              className="column w-100 bg-7 btn btn-lg justify-center align-left"
              style={{ marginTop: 7 ,borderRadius:10}}
              onClick={() => {
                //setCustomer(i);
                dispatch(suspendActions.activateSuspend(e.id));
                
                toggle();
              }}
            >
              <div className="row ">
                <div
                  className="text-1"
                  style={{ fontSize: 15, marginLeft: 10 }}
                >
                  Date : {e?.date}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <CommonModal
      children={renderModal}
      show={show}
      onOk={toggle}
      ok={false}
      title="Pending"
      toggleModal={toggle}
    />
  );
}

export default SuspendModal;
