import React, { useEffect, useState } from "react";
import KeyboardIcon from "../../../assets/svgs/KeyboardIcon";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import layouts, { defaultName } from "../../../utils/keyboardLayouts";
import * as Hangul from "hangul-js"
import Motion1 from "../../../common/components/Motion1";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

function Navbar({ getInvoices, text, setText, from, setFrom, to, setTo ,prev,
  next,}) {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [klayouts, setKlayouts] = useState(layouts);
  const [lang, setLang] = useState(0);
  const [layoutName, setLayoutName] = useState("default")

  const toggleLang = () => {
    if (lang >= 0 && lang < klayouts.length - 1) {
      setLang(lang + 1);
    } else {
      setLang(0);
    }
  };

  return (
    <div>
      <div className="row  align-center justify-start p-1">
        <p className="text-2" style={{ width: "60px" }}>
          {" "}
          Search &ensp;
        </p>
        <input
          value={text}
          onChange={async (e) => {
            setText(e.target.value);
            getInvoices(e.target.value);
          }}
          style={{
            fontSize: 13,
            height: 25,
            border: "2px solid #777",
            paddingLeft: 10,
            fontWeight: "bold",
            fontFamily: "Arial",
            width: "40%",
          }}
          type="text"
        />
      </div>
      <div className="row  align-center justify-start p-1">
        <p className="text-2" style={{ width: "60px" }}>
          {" "}
          From &ensp;
        </p>
        <input
          value={from}
          style={{
            fontSize: 13,
            height: 25,
            border: "2px solid #777",
            paddingLeft: 10,
            fontWeight: "bold",
            fontFamily: "Arial",
            width: "15%",
          }}
          type="date"
          onChange={(e) => setFrom(e.target.value)}
        />
        &ensp;
        <p className="text-2 ml-1"> To &ensp;</p>
        <input
          value={to}
          style={{
            height: 25,
            border: "2px solid #777",
            fontSize: 13,
            paddingLeft: 10,
            fontWeight: "bold",
            fontFamily: "Arial",
            width: "15%",
          }}
          type="date"
          onChange={(e) => setTo(e.target.value)}
        />
        &ensp;
        <KeyboardIcon
          onClick={() => {
            setShowKeyboard(!showKeyboard);
          }}
          color="#000"
        />
         <div className="row ml-1">
         
          <div
            className="btn bg-1 ml-1"
            onClick={prev}
            style={{ padding: 10, height: 40 }}
          >
            <FaArrowUp style={{ color: "#fff" }} />
          </div>
          <div
            className="btn bg-1 ml-1"
        
            onClick={next}
            style={{ padding: 10, height: 40 }}
          >
            <FaArrowDown style={{ color: "#fff" }} />
          </div>
        </div>
        {showKeyboard ? (
          <div className="keyboard-add">
            <Motion1>
            <Keyboard
              layout={klayouts[lang]}
              display={defaultName}
              layoutName={layoutName}
              onKeyPress={(e) => {
                if (e === "{caps}") {
                  setLayoutName(layoutName === "default" ?"shift":"default")
                  return;
                }
                if (e === "{enter}") {
                  setShowKeyboard(!showKeyboard);
                  return;
                }
                if (e === "{close}") {
                  setShowKeyboard(!showKeyboard);
                  return;
                }
                if (e === "{lang}") {
                  toggleLang();
                  return;
                }
                if (e === "{space}") {
                  setText(text + " ");
                  return;
                }

                if (e === "{tab}") {
                  setText(text + "        ");
                  return;
                }
                if (e === "{clear}") {
                  setText("");
                  return;
                }


                if (e === "{bksp}") {
                  if (text.length > 0) {
                    setText(text?.slice(0, -1));
                    return;
                  }
                  return;
                }
                if(lang === 1)
                {
                  // console.log(text+""+e);
                let parsed = Hangul.disassemble(text + "" + e);
                console.log(parsed);
                console.log(Hangul.assemble(["ㄲ", "ㅑ", "ㅈ"]),"99u9j9j98i");
                setText(Hangul.assemble(parsed))
                // assemble characters
                // setValue(Hangul.assemble(parsed));
                }else{
                  setText(text + "" + e);
                }
              }}
            />
            </Motion1>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Navbar;
