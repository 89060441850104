const initialState = [];


const SET_PRODUCTS = 'SET_PRODUCTS';


const productReducer =(state =initialState,action) => {
    switch (action.type) {
        case SET_PRODUCTS:    
            return [...action.payload]
              break;
        }
          return state;
}

export default productReducer;