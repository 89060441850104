

  import React from 'react'
  
  function Error() {
      return (
        <div className="container-center">
            <p className="header blue">Welcome to Kim Mart POS</p>
            <p className="sub-header cadeblue">Site is Under Contruction</p>
        </div>
      )
  }
  
  export default Error
  