
const initialState = {
  status:false,
  moved:false
};

const TOGGLE_SCREEN = "TOGGLE_SCREEN";
const SET_MOVED = "SET_MOVED";


const customerScreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SCREEN:
      return {
        ...state,
        status:action.payload
      };
    
    case SET_MOVED:
        return {
          ...state,
          moved:action.payload
        };
      
   
    default:
      break;
  }
  return state;
};

export default customerScreenReducer;
