import React, { Suspense, useEffect, useState } from "react";
import ButtonSm from "../../../common/components/ButtonSm";
import ButtonLg from "../../../common/components/ButtonLg";

import { useSelector, useDispatch } from "react-redux";
import { n_format } from "../../../utils/common";

import * as commonActions from "../../../state/common/commonActions";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import * as userActions from "../../../state/user/userActions";
import * as suspendActions from "../../../state/suspend/suspendActions";
import NumberBtn from "../../../common/components/NumberBtn";
import Drag from "../../../assets/svgs/Drag";
import Operations from "../../../assets/svgs/Operations";
import Edit from "../../../assets/svgs/Edit";
import { Link, useHistory } from "react-router-dom";

function HomeFooter({isCustomer}) {
  const order = useSelector((state) => state.order);
  const customer = useSelector(state => state.customer);
  const suspend = useSelector(state => state.suspend);

  const [screenState, setScreenState] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const history = useHistory();

  // Discount States
  const [showDiscount, setShowDiscount] = useState(false);
  const [percent, setPercent] = useState("");
  const [all, setAll] = useState(true);
  const [oldPercent, setOldPercent] = useState(0);

  // Tender States
  const [showTender, setShowTender] = useState(false);

  // Operation States
  const [showOperation, setShowOperation] = useState(false);

  // Edit States

  const [showEdit, setShowEdit] = useState(false);

  // Add New States
  const [showAddNew, setShowAddNew] = useState(false);

  // Cancel States
  const [showCancel, setShowCancel] = useState(false);

  // Confirm Popup
  const [showConfirm, setShowConfirm] = useState(false);


  // Invoice Popup

  const [showInvoice, setShowInvoice] = useState(false)


  // Confirm Logout
  const [showConfirmLogout, setShowConfirmLogout] = useState(false)


  // Suspend 
  const [suspendTitle, setSuspendTitle] = useState('Suspend');
  const [showSuspend, setShowSuspend] = useState(false)


  const toggleSuspend = () => {
    setShowSuspend(!showSuspend)
  }
  

 

  const onOkConfirmLogout =() => {
    dispatch(commonActions.trashOff())
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    dispatch(userActions.emptyUser());
    dispatch(suspendActions.emptySuspend());
    history.push("/");
  }


  const toggleInvoice = (toggleConfirmParam =() =>{}) => {
    setShowInvoice(!showInvoice);
    toggleConfirmParam && toggleConfirmParam();
  }
 

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const dispatch = useDispatch();

  let pureTotalAmount = 0.0;
  let pureQuantity = 0.0;
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [changedAmount, setChangedAmount] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);

  useEffect(() => {
    setOrderAmount();
  }, [order.products]);

  const setOrderAmount = () => {
    order.products.forEach((e) => {
      const oldTotal = n_format(e?.quantity) * n_format(e?.price);
      const discount =
        (n_format(oldTotal) * n_format(e?.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = +pureTotalAmount + +total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = +pureQuantity + +e?.quantity;
    });
    const discount =
      (n_format(order.discount) * n_format(pureTotalAmount)) / 100;
    setOrderDiscount(discount);
    setTotalAmount(pureTotalAmount);
    setTotalQuantity(pureQuantity);
  };

  const onOk = () => {
    if (all) {
      console.log(percent, "inside Ok");
      dispatch(orderActions.setDiscount(percent));
    } else {
      dispatch(orderActions.setOrderProducts(order.selected_product_item));
    }
    setDiscount(true);
  };

  const changePercent = (num) => {
    setPercent(percent + "" + num);
  };

  const changePercentDelete = (allDelete) => {
    if (allDelete) {
      setPercent("");
      setChangedAmount("0.00");
    } else {
      setPercent(percent?.slice(0, -1));
    }
  };
  const changeCalculate = (perc) => {
    if (all) {
      if (perc) {
        setPercent(perc);
        setChangedAmount((n_format(totalAmount) * n_format(perc)) / 100);
      } else {
        if (n_format(percent) > 0)
          setChangedAmount((n_format(totalAmount) * n_format(percent)) / 100);
      }
    } else {
      if (perc) {
        dispatch(
          orderActions.setSelectedProduct({
            ...order.selected_product_item,
            discount_percent: n_format(perc),
          })
        );
        setPercent(perc);
      } else {
        dispatch(
          orderActions.setSelectedProduct({
            ...order.selected_product_item,
            discount_percent: n_format(percent),
          })
        );
      }
    }
  };

  useEffect(() => {
    setOrderAmount();
    changeCalculate();
  }, [all, percent]);

  const renderModal = () => {
    let selectedTotal = 0;
    if (n_format(order.selected_product_item?.discount_percent) > 0) {
      const pureTotal =
        n_format(order.selected_product_item?.price) *
        n_format(order.selected_product_item?.quantity);
      const selectedDiscount =
        (n_format(order.selected_product_item?.discount_percent) * pureTotal) /
        100;
      selectedTotal = pureTotal - selectedDiscount;
    }
    return (
      <div className="w-100">
        <div className="column justify-center w-100">
          <div className="row w-100 justify-end ">
            <div
              className={`modal-btn bg-1 text-1 ${all ? "active-scale" : ""}`}
              onClick={() => setAll(true)}
            >
              Invoice
            </div>
            <div
              className={`modal-btn bg-3 text-1 ${!all ? "active-scale" : ""}`}
              onClick={() => setAll(false)}
            >
              Item
            </div>
          </div>
        </div>
        <div className="w-100 row align-right justify-between">
          <div className="">
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(10)}
            >
              10%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(20)}
            >
              20%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(30)}
            >
              30%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(40)}
            >
              40%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(50)}
            >
              50%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(60)}
            >
              60%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(70)}
            >
              70%
            </div>
          </div>
          <div className="numpad-grid mt-1">
            <div className="column w-100 align-right ">
              <div className="justify-between w-100 row">
                <span
                  className="text-1 "
                  style={{ fontSize: 13, textAlign: "left" }}
                >
                  Amount
                </span>
                <input
                  type="text"
                  value={
                    !all
                      ? n_format(selectedTotal || 0)
                      : n_format(changedAmount)
                  }
                  readOnly
                  className="border-bg-1 numpad-input"
                />
              </div>
              <div className="justify-between w-100 row">
                <span
                  className="text-1 "
                  style={{ fontSize: 13, textAlign: "left" }}
                >
                  Percentage
                </span>
                <input
                  type="text"
                  className="border-bg-3 numpad-input"
                  value={percent}
                  autoFocus={true}
                  style={{
                    background: "rgba(0,0,250,0.41)",
                    color: "#fff",
                    padding: 5,
                  }}
                />
              </div>
            </div>
            <div className="row justify-end">
              <NumberBtn onClick={() => changePercent(1)} title={1} />
              <NumberBtn onClick={() => changePercent(2)} title={2} />
              <NumberBtn onClick={() => changePercent(3)} title={3} />
              <NumberBtn
                onClick={() => changePercentDelete(true)}
                fontSize={12}
                title={"Delete"}
              />
            </div>
            <div className="row justify-end">
              <NumberBtn onClick={() => changePercent(4)} title={4} />
              <NumberBtn onClick={() => changePercent(5)} title={5} />
              <NumberBtn onClick={() => changePercent(6)} title={6} />
              <NumberBtn
                onClick={() => changePercentDelete(false)}
                fontSize={12}
                title={"Back space"}
              />
            </div>
            <div className="row justify-end">
              <div className="">
                <div className="row">
                  <NumberBtn onClick={() => changePercent(7)} title={7} />
                  <NumberBtn onClick={() => changePercent(8)} title={8} />
                  <NumberBtn onClick={() => changePercent(9)} title={9} />
                </div>
                <div className="row justify-end">
                  <NumberBtn onClick={() => changePercent(0)} title={0} />
                  <NumberBtn onClick={() => changePercent(".")} title={"."} />
                  <NumberBtn
                    onClick={() => changePercentDelete(true)}
                    fontSize={12}
                    title={"Clear"}
                  />
                </div>
              </div>
              <div className="">
                <NumberBtn
                  onClick={() => changeCalculate()}
                  down
                  fontSize={12}
                  title={"Enter"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const setQuanity = () => {
    if (order.products.length > 0) {
      dispatch(commonActions.toggleModal());
    }
  };

  const setDiscount = (save = false) => {
    if (order.products.length > 0) {
      if (save) {
        // setOldPercent(0);
        // setPercent("");
        setPercent("");
        setAll(true);

        setShowDiscount(!showDiscount);
      } else {
        if (showDiscount) {
          setChangedAmount(0.0);
          setPercent("");
          dispatch(
            orderActions.setSelectedProduct({
              ...order.selected_product_item,
              discount_percent: n_format(oldPercent),
            })
          );
        } else {
          setChangedAmount(0.0);
          setOldPercent(
            n_format(order.selected_product_item?.discount_percent)
          );
          setPercent("");
          changePercent(
            n_format(order.selected_product_item?.discount_percent) > 0
              ? n_format(order.selected_product_item?.discount_percent)
              : ""
          );

          setAll(true);
        }
        setShowDiscount(!showDiscount);
      }
    }
  };

  useEffect(() => {
    if(suspend?.data?.length > 0)
    setSuspendTitle("Load Pending")
    else
    setSuspendTitle("Suspend")
  }, [suspend])

  const onScreenStateChange = () => {
    if(order?.products?.length > 0){
      console.log("here");
      dispatch(suspendActions.addSuspend({
        customer,
        order,
      }));
      dispatch(commonActions.toggleTrash())
      dispatch(orderActions.emptyOrder());
      dispatch(customerActions.emptyCustomer());
    }
else if(suspend?.data?.length > 0){
  setShowSuspend(!showSuspend)
}
    // dispatch(commonActions.toggleLock());
    // setScreenState(!screenState);
  };

  const scrollDown = () => {
    let index = 0;
    order.products.forEach((e, i) => {
      if (
        order.selected_product === e?.barcode &&
        i < order.products.length - 1
      ) {
        index = i + 1;
        return;
      }
    });
    dispatch(orderActions.setSelectedProduct(order.products[index]));
  };

  const scrollUp = () => {
    let index = 0;
    order.products.forEach((e, i) => {
      if (order.selected_product === e?.barcode && i > 0) {
        index = i - 1;
        return;
      }
    });
    dispatch(orderActions.setSelectedProduct(order.products[index]));
  };

  const deleteProduct = () => {
    dispatch(commonActions.toggleTrash());
  };

  // Tender Modal

  const toggleTender = (saved) => {
    if(order?.products?.length >0 )
    setShowTender(!showTender);
  };

  // Operation Modal

  const renderOperation = () => {
    return <Operations onClick={toggleOperation} />;
  };

  const toggleOperation = () => setShowOperation(!showOperation);

  // Cancel Modal

  const toggleCancel = () => setShowCancel(!showCancel);
  const toggleConfirmLogout = () => setShowConfirmLogout(!showConfirmLogout)

  const onOkCancel = () => {
    dispatch(commonActions.toggleTrash())
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    setShowCancel(false);
  };

  // Add New

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  // Edit

  const renderEdit = () => {
    return <Edit onClick={toggleEdit} />;
  };

  const toggleEdit = () => setShowEdit(!showEdit);

  return (
    <>
      
      <div className="home-footer customer-footer" >
        <div className="row">
          
          <ButtonLg
            bg="bg-5"
            text="text-2"
            title="Total Qty"
            value={(totalQuantity)}
          />
          <ButtonLg
            bg="bg-5"
            text="text-2"
            title="Sub Total"
            value={"$" + n_format(totalAmount)}
          />
          <ButtonLg
            bg="bg-5"
            text="text-2"
            title="Discount"
            value={n_format(orderDiscount)}
          />
          <ButtonLg
            bg="bg-6"
            title="Grand Total"
            value={"$" + n_format(totalAmount - n_format(orderDiscount))}
          />
        </div>
       
        
      </div>
    </>
  );
}

export default HomeFooter;
