
const initialState = {
  invoices: [],
  customers: [],
  checkInvoices: [],
};

const ADD_OFFLINE_INVOICE = "ADD_OFFLINE_INVOICE";
const DELETE_OFFLINE_INVOICE = "DELETE_OFFLINE_INVOICE";
const EMPTY_OFFLINE_INVOICES = "EMPTY_OFFLINE_INVOICES";

const ADD_CHECK_INVOICE = "ADD_CHECK_INVOICE";
const DELETE_CHECK_INVOICE = "DELETE_CHECK_INVOICE";
const EMPTY_CHECK_INVOICES = "EMPTY_CHECK_INVOICES";

const ADD_OFFLINE_CUSTOMER = "ADD_OFFLINE_CUSTOMER";
const DELETE_OFFLINE_CUSTOMER = "DELETE_OFFLINE_CUSTOMER";
const EMPTY_OFFLINE_CUSTOMERS = "EMPTY_OFFLINE_CUSTOMERS";

const EMPTY_OFFLINE = "EMPTY_OFFLINE";

const offlineReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OFFLINE_INVOICE:
      console.log(action.payload, '1');
      return {
        ...state,
        invoices: [...state.invoices, { ...action.payload, date: new Date().toLocaleString() }]
      };
    case DELETE_OFFLINE_INVOICE:
      const newData2 = state.invoices.filter((e, i) => e.id !== action.payload);
      return {
        ...state,
        invoices: [...newData2]
      };

    case EMPTY_OFFLINE_INVOICES:
      return {
        ...state,
        invoices: []
      };

    case ADD_CHECK_INVOICE:
      console.log(action.payload, '1');
      return {
        ...state,
        checkInvoices: [...state.invoices, { ...action.payload, date: new Date().toLocaleString() }]
      };
    case DELETE_CHECK_INVOICE:
      const newData4 = state.invoices.filter((e, i) => e.id !== action.payload);
      return {
        ...state,
        checkInvoices: [...newData4]
      };

    case EMPTY_CHECK_INVOICES:
      return {
        ...state,
        checkInvoices: []
      };



    case ADD_OFFLINE_CUSTOMER:
      console.log(action.payload, '1');
      return {
        ...state,
        customers: [...state.customers, { ...action.payload, date: new Date().toLocaleString() }]
      };
    case DELETE_OFFLINE_CUSTOMER:
      const newData3 = state.customers.filter((e, i) => e.id !== action.payload);
      return {
        ...state,
        customers: [...newData3]
      };

    case EMPTY_OFFLINE_CUSTOMERS:
      return {
        ...state,
        customers: []
      }
    case EMPTY_OFFLINE:
      return {
        invoices: [],
        customers: [],
        checkInvoices: []
      }
    default:
      break;
  }
  return state;
};

export default offlineReducer;
