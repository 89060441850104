import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalHeader from "./ModalHeader";
import * as orderActions from "../../state/order/orderActions";
import * as commonActions from "../../state/common/commonActions";
import { FaMinus, FaPlus } from "react-icons/fa";
import { n_format } from "../../utils/common";

function ModalFull() {
  const common = useSelector((state) => state.common);
  const order = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const onAdd = () => {
    dispatch(
      orderActions.setSelectedProduct({
        ...order.selected_product_item,
        quantity: parseInt(order.selected_product_item?.quantity)  + 1,
      })
    );
  };

  const onMinus = () => {
    if (order.selected_product_item?.quantity > 1)
      dispatch(
        orderActions.setSelectedProduct({
          ...order.selected_product_item,
          quantity: order.selected_product_item?.quantity - 1,
        })
      );
  };

  const toggleModal = () => {
    dispatch(commonActions.toggleModal());
  };

  const onOk = () => {
    dispatch(orderActions.setOrderProducts(order.selected_product_item));
    dispatch(commonActions.toggleModal());
  };

  return (
    <div className={common.modal_show ? `modal-full` : `modal-hide`}>
      <div className="modal-body">
        <div className="w-100">
          <ModalHeader
            handleOk={onOk}
            title={"Quantity"}
            handleCancel={toggleModal}
          />
          <br />
          <div className="row justify-center w-100">
            <div className="modal-btn bg-1" onClick={onAdd}>
              <FaPlus />
            </div>
            <input
              className="input-modal"
              value={(order.selected_product_item?.quantity)}
              readOnly
              type="text"
            />
            <div className="modal-btn bg-1" onClick={onMinus}>
              <FaMinus />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalFull;
