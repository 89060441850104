export const toggleScreen = (payload) => {
    return {
      type: "TOGGLE_SCREEN",
      payload,
    };
  };
  
  export const setMoved = (payload) => {
    return {
      type: "SET_MOVED",
      payload,
    };
  };
  
  