import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import ProductItem from '../../pages/HomePage/components/ProductItem';
import * as orderActions from "../../state/order/orderActions"
import ProductService from "../../api/ProductAPI"
import ButtonMd from './ButtonMd';
import { data } from "./../../utils/offline/products.json";

function BagProduct() {
    const dispatch = useDispatch();



    const [bag, setBag] = useState(data.find(e => e.item_code === "bag"))

    useEffect(() => {
        getBag()
    }, [])

    const getBag = async () => {
        const data = await ProductService.getBagProduct();
        if (data)
            setBag(data)
    }

    const handleClick = (e) => {
        dispatch(
            orderActions.setOrderProducts(e)
        );
    }
    return (

        <div className="bag">
            <ButtonMd title={"Bag"} height="30px" />
            <ProductItem onClick={() => handleClick(bag)} item={bag} />
        </div>
    )
}

export default BagProduct
