import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { n_format } from "../../../utils/common";
import * as orderActions from "../../../state/order/orderActions";
import ButtonSm from "../../../common/components/ButtonSm";
import { FaTrash } from "react-icons/fa";

function TableRow({ item, index = 1, latest,customer }) {
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const setSelected = () => {
    dispatch(orderActions.setSelectedProduct(item));
  };
  const oldTotal = n_format(item?.quantity) * n_format(item?.price);
  const discount = (n_format(oldTotal) * n_format(item?.discount_percent)) / 100;
  const total = n_format(oldTotal) - n_format(discount);

  const deleteProduct = () => {
    console.log(item);
    dispatch(orderActions.deleteProduct(item?.barcode));
  };

  return (
    <div
      onClick={setSelected}
    
      className={`table-row table-customer row ${
        order.selected_product === item?.barcode ? "bg-3" : "bg-2"
      } align-center`}
    >
      <div className="w-20 ">
        <p className="text-2 t-center" style={{fontSize:22}}>{index + 1}</p>
      </div>
      <div className="w-100 ">
        <p className="text-2 t-center" style={{fontSize:22}}>{item?.name_en}</p>
      </div>
      <div className="w-30 ">
        <p className="text-2 t-center" style={{fontSize:22}}>{(item?.quantity)}</p>
      </div>
      <div className="w-30 ">
        <p className="text-2 t-center" style={{fontSize:22}}>{"$" + n_format(item?.price)}</p>
      </div>
      <div className="w-30 ">
        <p className="text-2 t-center" style={{fontSize:22}}>{"$" + n_format(discount)}</p>
      </div>
      <div className="w-30 ">
        <p className="text-2 t-center" style={{fontSize:22}}>{"$" + n_format(total)}</p>
      </div>
    </div>
  );
}

export default TableRow;
