

let permissionStatus = null;
let screensInterface = null;


window.addEventListener('load', async () => {
  // eslint-disable-next-line no-restricted-globals
  if (!('getScreens' in self) || !('isExtended' in screen) || !('onchange' in screen)) {
    showWarning("Please use Chrome 92+ to demo new multi-screen features");
  } else {
    // eslint-disable-next-line no-restricted-globals
    screen.addEventListener('change', () => { updateScreens(/*requestPermission=*/false); });
    permissionStatus = await navigator.permissions.query({name:'window-placement'});
    permissionStatus.addEventListener('change', (p) => { permissionStatus = p; updateScreens(/*requestPermission=*/false); });
  }
  updateScreens(/*requestPermission=*/false);
});


// Show Warning
function showWarning(text) {
    let warning = document.getElementById("warning");
    if (warning) {
      warning.hidden = !text;
      warning.innerText = text;
    }
    if (text)
      console.error(text);
  }
  

// SetScreenListners
function setScreenListeners() {
    let screens = screensInterface ? screensInterface.screens : [ window.screen ];
    for (const s of screens)
      s.onchange = () => { updateScreens(/*requestPermission=*/false); };
  }

// Ask Permission and get Screens
async function getScreensWithWarningAndFallback(requestPermission) {
    // eslint-disable-next-line no-restricted-globals
    if ('getScreens' in self) {
      if (!screensInterface && (permissionStatus.state === 'granted' ||
                                (permissionStatus.state === 'prompt' && requestPermission))) {
        // eslint-disable-next-line no-undef
        screensInterface = await getScreens().catch(()=>{ return null; });
        console.log(screensInterface);
        if (screensInterface && screensInterface?.length > 1) {
          screensInterface.addEventListener('screenschange', () => { updateScreens(/*requestPermission=*/false); setScreenListeners(); });
          setScreenListeners();
        }
      }
      if (screensInterface && screensInterface?.screens && screensInterface?.screens?.length > 1)
        showWarning();  // Clear any warning.
      else if (screensInterface  && screensInterface?.screens && screensInterface.screens.length == 1)
        showWarning("Please extend your desktop over multiple screens for full demo functionality");
      else if (requestPermission || permissionStatus.state === 'denied')
        showWarning("Please allow the Window Placement permission for full demo functionality");
  
      if (screensInterface  && screensInterface?.screens) {
        console.log("INFO: Detected " + screensInterface.screens.length + " screens:");
        for (let i = 0; i < screensInterface.screens.length; ++i) {
          const s = screensInterface.screens[i];
          console.log(`[${i}] (${s.left},${s.top} ${s.width}x${s.height}) isExtended:${s.isExtended}` +
                      `isPrimary:${s.isPrimary} isInternal:${s.isInternal}`);
        }
        return screensInterface.screens;
      }
    }
  
    // eslint-disable-next-line no-restricted-globals
    console.log(`INFO: Detected window.screen: (${screen.left},${screen.top} ${screen.width}x${screen.height}) isExtended:${screen.isExtended}`);
    return [ window.screen ];
  }




// Update Screens 
export async function updateScreens(requestPermission = true) {
    const screens = await getScreensWithWarningAndFallback(requestPermission);
    console.log(screens);
    //toggleFullscreen(1);
    return screens;
  }

  export async function toggleFullscreen(screenId) {
    toggleElementFullscreen(document.getElementById('root'), screenId);
  }

  async function toggleElementFullscreen(element, screenId) {
    if (typeof(screenId) != "number") {  // Ignore EventListener's event args.
      if (document.fullscreenElement == element)
        document.exitFullscreen();
      else
        element.requestFullscreen();
      return;
    }
    let fullscreenOptions = { navigationUI: "auto" };
    const screens = await updateScreens(/*requestPermission=*/false);
    if (screens.length > 1 && screenId < screens.length) {
      console.log('Info: Requesting fullscreen on another screen.');
      // TODO(msw): Use screen.id and not an index.
      fullscreenOptions.screen = screens[screenId];
    }
    element.requestFullscreen(fullscreenOptions);
  }

 /// Slide Screen ////
  
function getFeaturesFromOptions(options) {
  console.log(options , "options")
  return "left=" + options.x + ",top=" + options.y +
         ",width=" + options.width + ",height=" + options.height;
}  


  export async function openSlideWindow() {
    const screens = await updateScreens(/*requestPermission=*/false);
    console.log(screens , "screens")
       // eslint-disable-next-line no-restricted-globals
         // eslint-disable-next-line no-restricted-globals
    let options = { x:screen.availLeft, y:screen.availTop, width:screen.availWidth, height:screen.availHeight/2 };
    if (screens && screens.length > 1) {
      options = { x:screens[1].availLeft, y:screens[1].availTop,
                  width:screens[1].availWidth, height:screens[1].availHeight };
    }
    const features = getFeaturesFromOptions(options);
    // TODO: Re-enable and use the fullscreen feature string option?
    console.log('INFO: Opening window with feature string: ' + features);
    const slide_window = window.open('https://google.com', '_blank', features);
    // TODO: Make the window fullscreen; this doesn't currently work:
    // slide_window.document.body.requestFullscreen();
    // TODO: Open another window or reposition the current window.
    // window.open('./notes.html', '_blank', getFeaturesFromOptions(options));
  }