export const EMPTY_PRODUCTS = 'EMPTY_PRODUCTS'
export const EMPTY_STATUS_PRODUCTS = 'EMPTY_STATUS_PRODUCTS'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'
export const ADD_STATUS_PRODUCT = 'ADD_STATUS_PRODUCT'
export const ADD_RETURN_PRODUCT = 'ADD_RETURN_PRODUCT'

export const emptyProducts = () => {
	return {
		type: EMPTY_PRODUCTS,
	}
}

export const emptyStatusProducts = () => {
	return {
		type: EMPTY_STATUS_PRODUCTS,
	}
}

export const addProduct = (payload) => {
	return {
		type: 'ADD_PRODUCT',
		payload,
	}
}

export const addStatusProduct = (payload) => {
	return {
		type: 'ADD_STATUS_PRODUCT',
		payload,
	}
}
export const addReturnProduct = (payload) => {
	return {
		type: 'ADD_RETURN_PRODUCT',
		payload,
	}
}

export const setSelectedProduct = (payload) => {
	return {
		type: 'SET_SELECTED_PRODUCT',
		payload,
	}
}
