import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Navbar from "./components/Navbar";
import ProductsTable from "./components/ProductsTable";
import * as orderActions from "../../state/order/orderActions";
import { Link, useHistory } from "react-router-dom";
import ProductService from "../../api/ProductAPI";

function ProductSearchContainer() {
  const [text, setText] = useState("");
  const [price, setPrice] = useState("");
  const [activeField, setActiveField] = useState("N");
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getProducts(text);
  }, [text]);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts(null,price);
    setCurrentPage(1);
  }, [price]);


  useEffect(() => {
    getProducts(text,null);
    setCurrentPage(1);
  }, [text]);

  const getProducts = async (text,price,page = currentPage) => {
    console.log(page,"909");
    try {
      setIsLoading(true)
      const data = await ProductService.searchProductsWith(text, price, 50, page);
      //console.log(data);
      if (data.data.length > 0) {
        setCurrentPage(data.current_page);
        setProducts([...data.data]);
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  };

  const nextProducts = async () => {
    getProducts(text,price,currentPage + 1);
  };

  const prevProducts = async () => {
    getProducts(text,price,currentPage - 1);
  };

  const [selected, setSelected] = useState(null);

  const addProductToOrder = async () => {
    dispatch(orderActions.setOrderProducts(selected));
    history.push("/home");
  };

  return (
    <div className="home-container bg-10">
      <div className="modal-header-lg row bg-1 align-center justify-center">
        <h3 style={{ color: "var(--white)" }}>Search Products</h3>
      </div>
      <div className="modal-actions-lg row justify-center">
        <div onClick={addProductToOrder} className="modal-btn-lg bg-6 text-1">
          Add
        </div>
        <Link to="/home" className="modal-btn-lg bg-1 text-1">
          Cancel
        </Link>
      </div>
      <Navbar
        price={price}
        setPrice={setPrice}
        text={text}
        setText={setText}
        next={nextProducts}
        prev={prevProducts}
        activeField={activeField}
        setActiveField={setActiveField}
      />
      <ProductsTable
        isLoading={isLoading}
        selected={selected}
        setSelected={setSelected}
        products={products}
        
      />
    </div>
  );
}

export default ProductSearchContainer;
