import React, { useEffect } from "react";
import ButtonMd from "../../../common/components/ButtonMd";

import CategoryService from "../../../api/CategoryAPI";
import { useDispatch, useSelector } from "react-redux";
import * as categoryActions from "../../../state/category/categoryActions";
import * as commonActions from "../../../state/common/commonActions";

function Categories() {
  const categories = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    //TODO Thunk
    getData();
  }, []);

  const getData = async () => {
    const data = await CategoryService.getCategories();
    if (data) dispatch(categoryActions.setCategories(data));
  };

  const handleClick = (category) => {
    console.log(category);
    if (category.id) dispatch(commonActions.setActiveCategory(category.id));
  };

  return (
    <div className="categories">
      {categories.map((e, i) => {
        return (
          <ButtonMd
            onClick={() => handleClick(e)}
            key={i}
            title={e.name_en}
            bgColor={e.color}
            height="30px"
          />
        );
      })}
    </div>
  );
}

export default Categories;
