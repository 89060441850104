import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import ProductsTable from "./components/ProductsTable";
import * as orderActions from "../../state/order/orderActions";
import * as customerActions from "../../state/customer/customerActions";
import * as suspendActions from "../../state/suspend/suspendActions";
import * as commonActions from "../../state/common/commonActions";
import { Link, useHistory } from "react-router-dom";
import InvoiceService from "../../api/InvoiceAPI";
import InvoiceModal from "./components/InvoiceModal";
import CommonModal from "../../common/components/CommonModal";
import ConfirmModal from "./components/ConfirmModal";
import { FaArrowLeft } from "react-icons/fa";

function ReturnPageContainer() {
  const children = <h1>Hello</h1>;

  // Load Suspend Data
  const suspend = useSelector((state) => state.suspend);
  const order = useSelector((state) => state.order);
  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)

  const dispatch = useDispatch();
  const history = useHistory();

  const [text, setText] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useEffect(() => {
    getInvoices(text, from, to);
  }, [text, from, to]);

  useEffect(() => {
    if (suspend?.data?.length > 0) {
      const activeSuspend = suspend?.data?.find(
        (e) => e.id === suspend?.activeId
      );
      if (activeSuspend) {
        dispatch(orderActions.fillOrder(activeSuspend?.order));
        dispatch(customerActions.fillCustomer(activeSuspend?.customer));
        dispatch(suspendActions.deleteSuspend(suspend?.activeId));
      }
    }
  }, [suspend.activeId]);

  const [invoices, setInvoices] = useState([]);
  // Confirm Popup
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmReturn, setShowConfirmReturn] = useState(false);
  const [showQuickConfirmReturn, setShowQuickConfirmReturn] = useState(false);

  const toggleConfirmReturn = (item) => {
    setSelected(item);
    setShowConfirmReturn(!showConfirmReturn);
  };

  const toggleQuickConfirmReturn = (e) => {
    setSelected(e);
    setShowQuickConfirmReturn(!showQuickConfirmReturn);
  };

  const onOkQuickConfirmReturn = () => {
    history.push("/return/quick");
  };

  const onOkConfirmReturn = () => {
    manualReturn();
  };
  const toggleConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async (query, from, to) => {
    const data2 = await InvoiceService.searchInvoices(
      query,
      from,
      to,
      null,
      null,
      user?.data?.branch?.id,
      user?.data?.id,
      null,
      1,
      12
    );
    const data = data2.data.invoices;
    console.log(data);
    if (data.data.length > 0) {
      setInvoices([...data.data]);
    } else {
      setInvoices([]);
    }
  };
  const [selected, setSelected] = useState(null);

  const [showInvoice, setShowInvoice] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const toggleInvoice = (toggleConfirmParam = () => {}) => {
    setShowInvoice(!showInvoice);
    toggleConfirmParam && toggleConfirmParam();
  };

  const currentSuspend = () => {
    if (order?.products?.length > 0) {
      dispatch(
        suspendActions.addSuspend({
          customer,
          order,
        })
      );
      dispatch(commonActions.toggleTrash());
      dispatch(orderActions.emptyOrder());
      dispatch(customerActions.emptyCustomer());
    }
  };

  const manualReturn = () => {
    history.push("/return/manual");
  };

  const partialInvoiceReturn = (selected) => {
    currentSuspend();
    const id = selected?.id;
    const invoice = {
      id: selected?.uniq_id,
      payBy: selected?.pay_by,
      amount: selected?.amount,
      redeemAmount: selected?.redeem_amount,
      dinomAmount: selected?.dinom_amount,
      return: true,
    };
    const user_id = selected?.user_id;
    const customer_id = selected?.customer?.id;

    const customer = {
      data: {
        ...selected?.customer,
      },
    };

    const discount = selected?.total_discount;

    const products = [
      ...selected?.order?.map((e, i) => {
        return {
          ...e,
          name_en: e?.product?.name_en,
          item_code: e?.product?.item_code,
          barcode: e?.product?.barcode,
        };
      }),
    ];

    const order = {
      products,
      discount,
      customer_id,
      invoice,
      user_id,
    };
    dispatch(
      suspendActions.addSuspend({
        customer,
        order,
        id,
      })
    );
    dispatch(commonActions.toggleTrash());
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    dispatch(suspendActions.activateSuspend(id));
    history.push("/home");
  };

  const setReturnInvoice = async (id) => {
    //suspend?.activeId
    setCurrentId(id);
    toggleInvoice(toggleConfirm);
  };

  const setReturnInvoiceToDB = async (id) => {
    const data = await InvoiceService.returnInvoice(id);
    getInvoices();
  };



  const nextProducts = async (query = '') => {
    try {
      setIsLoading(true);
      const data2 = await InvoiceService.searchInvoices('', from, to, null, null, user?.data?.branch?.id, user?.data?.id, null, currentPage + 1,12);
      const data = data2?.data?.invoices;

      console.log(data);
      if (data?.data?.length > 0) {
        setCurrentPage(data?.current_page);
        setInvoices(data?.data);
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }

  const prevProducts = async (query = '') => {
    try {

      setIsLoading(true)
      const data2 = await InvoiceService.searchInvoices('', from, to, null, null, user?.data?.branch?.id, user?.data?.id, null, currentPage - 1,12);
      const data = data2?.data?.invoices;
      console.log(data2);
      if (data?.data?.length > 0) {
        setCurrentPage(data?.current_page);
        setInvoices(data?.data);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)

    }

  }

  return (
    <div className="home-container bg-10">
      <div className="modal-header-lg row bg-1 align-center justify-center">
        <h3 style={{ color: "var(--white)" }}>Return Invoice</h3>
      </div>
      <div className="modal-actions-lg row justify-center">
        <Link to="/operation" className="modal-btn-lg bg-1 row justify-center align-center text-1">
        <FaArrowLeft color="#fff" /> &nbsp; Back
        </Link>
      </div>
      <Navbar
        getInvoices={getInvoices}
        text={text}
        setText={setText}
        to={to}
        setTo={setTo}
        from={from}
        setFrom={setFrom}
        next={nextProducts}
        prev={prevProducts}
      />
      <ProductsTable
        selected={selected}
        setSelected={setSelected}
        invoices={invoices}
        setReturnInvoice={toggleQuickConfirmReturn}
        partialInvoiceReturn={toggleConfirmReturn}
        isLoading={isLoading}
      />

      <ConfirmModal
        toggle={toggleConfirm}
        toggleInvoice={toggleInvoice}
        show={showConfirm}
        returnId={currentId}
        completeData={selected}
        getInvoices={getInvoices}
      />
      <CommonModal
        children={() => {}}
        show={showConfirmReturn}
        onOk={onOkConfirmReturn}
        title={
          <p style={{ textAlign: "center" }}>
            Confirm Return ?
            <br />
            {selected?.uniq_id}
          </p>
        }
        toggleModal={toggleConfirmReturn}
      />

      <CommonModal
        children={() => {}}
        show={showQuickConfirmReturn}
        onOk={onOkQuickConfirmReturn}
        title={
          <p style={{ textAlign: "center" }}>
            Confirm Quick Return ?
            <br />
            {selected?.uniq_id}
          </p>
        }
        toggleModal={toggleQuickConfirmReturn}
      />
      <InvoiceModal
        toggle={toggleInvoice}
        invoice={selected}
        show={showInvoice}
      />
    </div>
  );
}

export default ReturnPageContainer;
