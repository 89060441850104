
const initialState = {
  data:{},
};

const ADD_CUSTOMER = "ADD_CUSTOMER";
const EMPTY_CUSTOMER = "EMPTY_CUSTOMER";
const FILL_CUSTOMER = "FILL_CUSTOMER";

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      return {
        ...state,
        data:action.payload
      };
    case EMPTY_CUSTOMER: 
    return {
        ...state,
        data:{}
    };
    case FILL_CUSTOMER:
      return {
        ...action.payload
      }
    
    default:
      break;
  }
  return state;
};

export default customerReducer;
