import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { n_format } from "../../../utils/common";
import * as orderActions from "../../../state/order/orderActions";
import ButtonSm from "../../../common/components/ButtonSm";
import { FaTrash } from "react-icons/fa";

function TableRow({ item, index = 1, latest,customer }) {
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const setSelected = () => {
    dispatch(orderActions.setSelectedProduct(item));
  };
  const oldTotal = n_format(item?.quantity) * n_format(item?.price);
  const discount = (n_format(oldTotal) * n_format(item?.discount_percent)) / 100;
  const total = n_format(oldTotal) - n_format(discount);

  const deleteProduct = () => {
    console.log(item);
    dispatch(orderActions.deleteProduct(item?.barcode));
  };

  return (
    <div
      onClick={setSelected}
      className={`table-row row ${
        order.selected_product === item?.barcode ? "bg-3" : "bg-2"
      } align-center`}
    >
      <div className="s-no ">
        <p className="text-2 t-center">{index + 1}</p>
      </div>
      <div className="description ">
        <p className="text-2 t-center">  { item?.name_en?.slice(0, 12)}</p>
      </div>
      <div className="barcode ">
        <p className="text-2 t-center">{item?.barcode}</p>
      </div>
      <div className="qty ">
        <p className="text-2 t-center">{(item?.quantity)}</p>
      </div>
      <div className="price ">
        <p className="text-2 t-center">{"$" + n_format(item?.price)}</p>
      </div>
      <div className="disc-p ">
        <p className="text-2 t-center">
          {n_format(item?.discount_percent) + "%"}
        </p>
      </div>
      <div className="disc-d ">
        <p className="text-2 t-center">{"$" + n_format(discount)}</p>
      </div>
      <div className="total ">
        <p className="text-2 t-center">{"$" + n_format(total)}</p>
      </div>
      {!customer ? (
        <div className="del ">
          {common?.trashActive ? (
            <ButtonSm
              title="Delete"
              onClick={deleteProduct}
              icon={<FaTrash color="#fff" />}
            />
          ) : (
            "-"
          )}
        </div>
      ) : null}
    </div>
  );
}

export default TableRow;
