import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TableRow from "./TableRow";

function ProductsTable({
  products = [],
  deleteProduct,
  changeQuantity,
  toggleChangeQuantity,
}) {
  const invoice = useSelector((state) => state.invoice);
  const active_category = useSelector((state) => state.common.active_category);

  return (
    <>
      <div className="w-100  justify-between row mt-2">
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 ">Name</span>
          </div>
        </div>
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 ">Barcode</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Original Qty</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Return Qty</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Price</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Discount</span>
          </div>
        </div>

        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Total Price</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Points</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Change Qty</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Delete</span>
          </div>
        </div>
      </div>
      <div className="product-table-scroll" style={{maxHeight:"60vh"}}>
      {products?.filter(e => e.quantity > 0).map((e) => {
        return (
          <TableRow
            e={e}
            deleteProduct={deleteProduct}
            changeQuantity={changeQuantity}
            toggleChangeQuantity={toggleChangeQuantity}
          />
        );
      })}
      </div>
      <div className="w-100  justify-between row">
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsTable;
