import React from 'react'
import { useDispatch } from 'react-redux';
import * as invoiceActions from "../../../state/invoice/invoiceActions";


function TableRow({e,selected,setSelected,index}) {


    return (
        
      <div className={`row ${selected?.id === e?.id ? 'selected' :''}`} onClick={() =>{
        setSelected(e);

      }}>
       
      <div className="w-40">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-2 ">{e.item_code}  </span>
        </div>
      </div>
      <div className="w-40">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-2 ">{e.barcode}</span>
        </div>
      </div>
      <div className="w-100 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-2 "> { e?.name_en?.slice(0, 12)}</span>
        </div>
      </div>
      <div className="w-30 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-2 ">-{e.stock}</span>
        </div>
      </div>
      <div className="w-30 ">
        <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
          <span className="text-2 ">${e.price}</span>
        </div>
      </div>
    </div>
    )
}

export default TableRow
