import React, { useEffect } from 'react'
import ProductItem from './ProductItem'

import ProductService from "../../../api/ProductAPI"
import { useDispatch, useSelector } from 'react-redux';
import * as productActions from "../../../state/product/productActions"
import * as orderActions from "../../../state/order/orderActions"

function ProductsTray() {
    
    const products = useSelector(state => state.products);
    const user = useSelector(state => state.user);
    const active_category = useSelector(state => state.common.active_category)
    const dispatch = useDispatch();
    
    useEffect(() => {
        //TODO Thunk 
        getData();
    }, []);

    const getData = async () => {
        const data = await ProductService.getFavProducts(user?.data?.branch?.id);
        dispatch(productActions.setProducts(data.data));
    }
    
    const handleClick = (e) => {
        dispatch(
            orderActions.setOrderProducts(e)
          );
    }

    return (
        <div className="products-tray row">
            {
                products.filter(e => e.category_id === active_category ).map((e,i) => {
                    return (
                        <ProductItem onClick={() => handleClick(e)} key={i} item={e} />
                    )
                })
            }
        </div>
    )
}

export default ProductsTray
