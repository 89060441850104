  
  export const activateSuspend = (payload) => {
      
    return {
      type: "ACTIVATE_SUSPEND",
      payload,
    };
  };

  export const addSuspend = (payload) => {
      
    return {
      type: "ADD_SUSPEND",
      payload,
    };
  };

  export const deleteSuspend = (payload) => {
      
    return {
      type: "DELETE_SUSPEND",
      payload,
    };
  };
  
  export const emptySuspend = (payload) => {
    return {
    type: "EMPTY_SUSPEND",
      payload,
    };
  };