const layouts = [{
    default: [
      "1 2 3 4 5 6 7 8 9 0 = {bksp} {lang} {clear}",
      "{tab} q w e r t y u i o p [ ] \u005C",
      "{caps} a s d f g h j k l ; ' {enter}",
      "z x c v b n m , . /",
      "- {space} .",
    ],
    shift: [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp} {lang} {clear}",
      "{tab} Q W E R T Y U I O P { } |",
      '{caps} A S D F G H J K L : " {enter}',
      "Z X C V B N M < > ?",
      "- {space} .",
    ],
  },{
    default: [
      "` 1 2 3 4 5 6 7 8 9 0 = {bksp} {lang} {clear}",
      "{tab} ㅂ ㅈ ㄷ ㄱ ㅅ ㅛ ㅕ ㅑ ㅐ ㅔ \u20a9",
      "{caps} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ {enter}",
      "ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ \u1173",
      "- {space} .",
    ],
    shift: [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp} {lang} {clear}",
      "{tab} ㅃ ㅉ ㄸ ㄲ ㅆ ㅛ ㅕ ㅑ ㅒ ㅖ \u20a9",
      "{caps} ㅁ ㄴ ㅇ ㄹ ㅎ ㅗ ㅓ ㅏ ㅣ {enter}",
      "ㅋ ㅌ ㅊ ㅍ ㅠ ㅜ \u1173",
      "- {space} .",
    ],
    
  },
];


export const defaultName ={
    "{bksp}": "Bksp",
    "{enter}": "Enter",
    "{space}": "Space",
    "{close}": "X",
    "{clear}": "Clear",
    "{lang}": "Lang",
    "{caps}":"Caps Lock",
    "{tab}":"Tab",
   }
export default layouts;
