import React from 'react'
import NumberBtn from './NumberBtn'

function NumPad({changePercent,changeCalculate,changePercentDelete}) {
    return (
        <div
              className="column align-center justify-center"
              style={{ marginTop: 15 }}
            >
              <div className="row justify-end">
                <NumberBtn onClick={() => changePercent(1)} title={1} />
                <NumberBtn onClick={() => changePercent(2)} title={2} />
                <NumberBtn onClick={() => changePercent(3)} title={3} />
                <NumberBtn
                  onClick={() => changePercentDelete(true)}
                  fontSize={12}
                  title={"Delete"}
                />
              </div>
              <div className="row justify-end">
                <NumberBtn onClick={() => changePercent(4)} title={4} />
                <NumberBtn onClick={() => changePercent(5)} title={5} />
                <NumberBtn onClick={() => changePercent(6)} title={6} />
                <NumberBtn
                  onClick={() => changePercentDelete(false)}
                  fontSize={12}
                  title={"Back space"}
                />
              </div>
              <div className="row justify-end">
                <div className="">
                  <div className="row">
                    <NumberBtn onClick={() => changePercent(7)} title={7} />
                    <NumberBtn onClick={() => changePercent(8)} title={8} />
                    <NumberBtn onClick={() => changePercent(9)} title={9} />
                  </div>
                  <div className="row justify-end">
                    <NumberBtn onClick={() => changePercent("0")} title={0} />
                    <NumberBtn onClick={() => changePercent(".")} title={"."} />
                    <NumberBtn
                      onClick={() => changePercentDelete(true)}
                      fontSize={12}
                      title={"Clear"}
                    />
                  </div>
                </div>
                <div className="">
                  <NumberBtn
                    onClick={() => changeCalculate()}
                    down
                    fontSize={12}
                    title={"Enter"}
                  />
                </div>
              </div>
            </div>
    )
}

export default NumPad
