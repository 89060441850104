import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import CommonModal from "../../../common/components/CommonModal";
import ModalHeader from "../../../common/components/ModalHeader";

function ChangeQuantity({ show, onOk, toggle, selectedProduct }) {
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(selectedProduct?.quantity);
  }, [selectedProduct?.quantity]);
  useEffect(() => {
    setQuantity(selectedProduct?.quantity);
  }, []);

  const onAdd = () => {
    if (quantity < selectedProduct?.quantity) {
      setQuantity(quantity + 1);
    }
  };

  const onMinus = () => {
    if (quantity > 0) setQuantity(quantity - 1);
  };

  const render = () => {
    return (
      <>
        <div className="text-1">
          {" "}
          Return Quantity : {selectedProduct?.quantity - quantity}
        </div>
        <div className="row justify-center w-100">
          {quantity < selectedProduct?.quantity ? (
            <div className="modal-btn bg-1" onClick={onAdd}>
              <FaPlus />
            </div>
          ) : null}

          <input
            className="input-modal"
            value={quantity}
            readOnly
            type="text"
          />
          <div className="modal-btn bg-1" onClick={onMinus}>
            <FaMinus />
          </div>
        </div>
      </>
    );
  };
  return (
    <CommonModal
      children={render}
      show={show}
      onOk={() => onOk(quantity)}
      title={
        <p style={{ textAlign: "center" }}>
          Update Quantity
          <br />
        </p>
      }
      toggleModal={toggle}
    />
  );
}

export default ChangeQuantity;
