import { ADD_PRODUCT, ADD_STATUS_PRODUCT, EMPTY_PRODUCTS, SET_SELECTED_PRODUCT, ADD_RETURN_PRODUCT, EMPTY_STATUS_PRODUCTS } from './stockActions'

const initialState = {
    products: [],
    status_products: [],
    return_products: [],
    selectedProduct: null
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_PRODUCT:
			return {
				...state,
				products: [action.payload],
			}
		case ADD_STATUS_PRODUCT:
			return {
				...state,
				status_products: [action.payload],
			}
		case ADD_RETURN_PRODUCT:
			return {
				...state,
				status_products: [action.payload],
			}
		case SET_SELECTED_PRODUCT:
			return {
				...state,
				selectedProduct: action.payload,
			}
		case EMPTY_PRODUCTS:
			return {
				...state,
				products: [],
				selectedProduct: null,
			}
		case EMPTY_STATUS_PRODUCTS:
			return {
				...state,
				status_products: [],
				selectedProduct: null,
			}
		default:
			break
	}
	return state
}

export default userReducer
