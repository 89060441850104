export const fillInvoices = (payload) => {
  return {
    type: "FILL_INVOICES",
    payload,
  };
};

export const setActiveInvoice = (payload) => {
    return {
      type: "SET_ACTIVE_INVOICE",
      payload,
    };
  };

export const emptyInvoices = (payload) => {
  return {
    type: "EMPTY_INVOICES",
    payload,
  };
};


export const setLatestInvoiceId = (payload) => {
 
  return {
    type: "SET_LAST_INVOICE_ID",
    payload,
  };
};


export const setLatestRInvoiceId = (payload) => {
 
  return {
    type: "SET_LAST_R_INVOICE_ID",
    payload,
  };
};
