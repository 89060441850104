import React, { useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import { useDispatch } from "react-redux";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import * as Hangul from "hangul-js"

import CustomerService from "../../../api/CustomerAPI";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import layouts, { defaultName } from "../../../utils/keyboardLayouts";
import Motion1 from "../../../common/components/Motion1";

function AddCustomer({ show, toggle }) {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  const [activeField, setActiveField] = useState("n");

  const [klayouts, setKlayouts] = useState(layouts);
  const [lang, setLang] = useState(0);
  const [layoutName, setLayoutName] = useState("default")

  const toggleLang = () => {
    if (lang >= 0 && lang < klayouts.length - 1) {
      setLang(lang + 1);
    } else {
      setLang(0);
    }
  };


  const handleChange = e => setPhone(e.target.value)
  const handleChangeName =e => setName(e.target.value)

  const dispatch = useDispatch();

  const onOk = async() => {

    if (phone) {
      const data = await CustomerService.addCustomer({name,phone});
      console.log(data);
      if (data.data) {
        setPhone("")
        dispatch(orderActions.setCustomer(data.data["id"]));
        dispatch(customerActions.addCustomer(data.data));
      }
    }
    toggle();
  };



  const renderModal = () => {
    return (
      <div className="column" style={{marginBottom:60}}>
        <div className="row justify-between">
          <div className="text-1" style={{ fontSize: 22 }}>
            Name
          </div>
          <input
            type="text"
            value={name}
            onChange={handleChangeName}
            onClick={() => {
              setName("");
              setActiveField('n')
            }}
            style={{textAlign:"left"}}
            className={`numpad-input-3 ${activeField == "n"?'active-field':''}` }
            />
        </div>
        <div className="row justify-between">
          <div className="text-1" style={{ fontSize: 22 }}>
            Phone
          </div>
          <input
            type="number"
            pattern="[0-9]*"
            value={phone}
            onChange={handleChange}
            onClick={() => {
              setPhone("");
              setActiveField("p")
            }}
            style={{textAlign:"left"}}
            className={`numpad-input-3 ${activeField == "p"?'active-field':''}` }
          />
        </div>
        <div className="keyboard-add">
        <Motion1>
        <Keyboard
          layout={klayouts[lang]}
          display={defaultName}
          layoutName={layoutName}
          onKeyPress={(e) => {
            if (e === "{caps}") {
              setLayoutName(layoutName === "default" ?"shift":"default")
              return;
            }
            if (activeField === "n") {
              if (e === "{enter}") {
                setActiveField("p");
                return;
              }
              if (e === "{space}") {
                setName(name + " ");
                return;
              }
              if (e === "{tab}") {
                setName(name + "        ");
                return;
              }
              if (e === "{lang}") {
                toggleLang();
                return;
              }
              if (e === "{clear}") {
                setName("");
                return;
              }

              if (e === "{bksp}"){
                if (name.length > 0) {
                  setName(name?.slice(0, -1));
                  return;
                }
                return;
              }
              if(lang === 1)
              {
                // console.log(text+""+e);
              let parsed = Hangul.disassemble(name + "" + e);
              console.log(parsed);
              console.log(Hangul.assemble(["ㄲ", "ㅑ", "ㅈ"]),"99u9j9j98i");
              setName(Hangul.assemble(parsed))
              // assemble characters
              // setValue(Hangul.assemble(parsed));
              }else{
                setName(name + "" + e);

              }
            } else {
              if (e === "{enter}") {
                onOk();
                return;
              }
              if (e === "{lang}") {
                toggleLang();
                return;
              }
              if (e === "{clear}") {
                setPhone("");
                return;
              }

              if (e === "{space}") {
                setPhone(phone + " ");
                return;
              }
              if (e === "{tab}") {
                setPhone(name + "        ");
                return;
              }

              if (e === "{bksp}"){

                if (phone.length > 0) {
                  setPhone(phone?.slice(0, -1));
                  return;
                }
                return;
              }

              setPhone(phone + "" + e);
            }
          }}
        />
      </Motion1>
      </div>
    
      </div>
      
    );
  };
  return (
    <CommonModal
    
      children={renderModal}
      show={show}
      onOk={onOk}
      title="Add New Customer"
      toggleModal={toggle}
    />
  
    
  );
}

export default AddCustomer;
