
const initialState = {
    data:null,
  };
  
  const ADD_USER = "ADD_USER";
  const EMPTY_USER = "EMPTY_USER";
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_USER:
          console.log(action.payload,'1');
        return {
          ...state,
          data:action.payload
        };
      case EMPTY_USER: 
      return {
          ...state,
          data:null
      }
      default:
        break;
    }
    return state;
  };
  
  export default userReducer;
  