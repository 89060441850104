export const setActiveCategory = (payload) => {
    return {
        type:"SET_ACTIVE_CATEGORY",
        payload
    }
}

export const toggleModal = () => { 
    return {
        type:"TOGGLE_MODAL",
    }
}


export const toggleLock = () => { 
    return {
        type:"TOGGLE_LOCK",
    }
}

export const setModalChildren = (payload) => {
    return {
        type:"SET_MODAL_CHILDREN",
        payload
    }
}

export const setCurrencies = (payload) => {
    return {
        type:"SET_CURRENCIES",
        payload
    }
}

export const setCurrentCurrency = (payload) => {
    return {
        type:"SET_CURRENT_CURRENCY",
        payload
    }
}


export const toggleTrash = () => {
    return {
        type:"TOGGLE_TRASH"
    }
}

export const trashOff = () => {
    return {
        type:"TOGGLE_OFF"
    }
}

export const toggleKeyboard = payload => {
    return {
        type:"TOGGLE_KEYBOARD",
        payload
    }
}
export const setText = (payload) => {
    return {
        type:"SET_TEXT",
        payload
    }
}

export const emptyText = (payload) => {
    return {
        type:"EMPTY_TEXT",
        payload
    }
}
export const backspaceText = () => {
    return {
        type:"BACKSPACE_TEXT",
    }
}

export const setActiveField = (payload) => {
    return {
        type:"SET_ACTIVE_FIELD",
        payload
    }
}

export const onEnter = (payload) => {
    return {
        type:"ON_ENTER",
        payload
    }
}

export const clearCache = () => {
    return {
        type:"CLEAR_CACHE"
    }
}

export const setCS = (payload) => {
    return {
        type:"SET_CUSTOMER_SCREEN",
        payload
    }
}

export const setLoading = (payload) => {
    return {
        type: "SET_LOADING",
        payload
    };
};
