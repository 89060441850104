import OrderService from '../api/OrderAPI'
import { useDispatch, useSelector } from 'react-redux'
import * as orderActions from '../state/order/orderActions'

const useOrders = () => {
	// Props
	const dispatch = useDispatch()
	const api_token = useSelector((state) => state.user?.data?.api_token)

	// States

	// Redux

	// Functions

	const getOrders = async (params, token = api_token) => {
		try {
			const response = await OrderService.getOrders(params, token)

			if (response?.success) {
				if (params?.returnOrders) {
					return response?.data
				} else if (params?.allOrders) {
					return response?.data
				} else if (params?.history) {
					return response?.data
				} else if (params?.cancelled) {
					return response?.data
				} else if (params?.onDelivery) {
					return response?.data
				} else {
					dispatch(orderActions.fillOrders([...response?.data]))
				}
			} else {
				console.log(response, 'Get Order Hook Error')
			}
		} catch (error) {
			console.log(error, 'Order API error')
		}
	}

	// Side Effects

	// JSX
	return [getOrders]
}

export default useOrders
