import React from 'react'
import { FaArrowDown, FaArrowUp, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import ButtonLg from './ButtonLg'
import ButtonMd from './ButtonMd'
import ButtonSm from './ButtonSm'
import * as commonActions from "../../state/common/commonActions"

function Lock() {
    const common = useSelector(state => state.common)

    const dispatch = useDispatch()

    return (
        <div className={common.lock ? `lock` :`modal-hide`} >
                 <div className="home-footer">
            
            <div className="row">
                <ButtonMd title="Qty" />
                <ButtonMd title="Price" />
                <ButtonMd title="Discount" />
                <ButtonMd title="Reprint" />
                <ButtonMd  title="Shift In/Out" />
                <ButtonMd title="Currency" />
                <ButtonMd bg="bg-7" title="Operation" />
                <ButtonMd bg="bg-8" title={"Pending"} style={{zIndex : 1}} onClick={() => dispatch(commonActions.toggleLock())} />
                <ButtonMd bg="bg-1" title="Cancel" />
                <ButtonMd bg="bg-9" title="Tender" />
            </div>
            <div className="row" >
            <div  className={`btn-md btn row t-center justify-between align-center bg-1`} style={{height:25,paddingRight:10,paddingLeft:10}}>
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 10H12V6H16V10ZM10 10H6V6H10V10ZM4 10H0V6H4V10ZM16 4H12V0H16V4ZM10 4H6V0H10V4ZM4 4H0V0H4V4Z" fill="white"/>
            </svg>
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 10H12V6H16V10ZM10 10H6V6H10V10ZM4 10H0V6H4V10ZM16 4H12V0H16V4ZM10 4H6V0H10V4ZM4 4H0V0H4V4Z" fill="white"/>
            </svg>
            </div>
            </div>
        </div>
        </div>
    )
}

export default Lock
