import React, { useEffect, useState } from "react";
import KeyboardIcon from "../../../assets/svgs/KeyboardIcon";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import ButtonSm from "../../../common/components/ButtonSm";
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaArrowUp,
} from "react-icons/fa";
import layouts, { defaultName } from "../../../utils/keyboardLayouts";
import * as Hangul from "hangul-js"
import { Motion, spring, presets } from "react-motion";
import Motion1 from "../../../common/components/Motion1";

function Navbar({
  setText,
  text,
  next,
  prev,
  price,
  setPrice,
  activeField,
  setActiveField,
}) {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [klayouts, setKlayouts] = useState(layouts);
  const [lang, setLang] = useState(0);
  const [layoutName, setLayoutName] = useState("default")

  const toggleLang = () => {
    if (lang >= 0 && lang < klayouts.length - 1) {
      setLang(lang + 1);
    } else {
      setLang(0);
    }
  };

  return (
    <div>
      <div className="row align-center justify-start p-1">
        <p className="text-2" style={{ width: "60px" }}>
          {" "}
          Search &ensp;
        </p>
        <input
          value={text}
          onChange={async (e) => {
            setText(e.target.value);
          }}
          style={{
            fontSize: 13,
            height: 35,
            border: "2px solid #777",
            paddingLeft: 10,
            fontWeight: "bold",
            fontFamily: "Arial",
            width: "40%",
          }}
          onClick={() => setActiveField("N")}
          onFocus={() => setActiveField("N")}
          type="text"
          placeholder="Search for Item Code or Barcode or Name"
        />
        &ensp;
        {/* <KoreanKeyBoard /> */}
        <KeyboardIcon
          onClick={() => {
            setShowKeyboard(!showKeyboard);
          }}
          color="#000"
        />
        {showKeyboard ? (
          <div className="keyboard-add">
            <Motion1>
            <Keyboard
              layout={klayouts[lang]}
              display={defaultName}
              layoutName={layoutName}
              onKeyPress={(e) => {
                if (e === "{caps}") {
                  setLayoutName(layoutName === "default" ?"shift":"default")
                  return;
                }
                
                if (e === "{enter}") {
                  setShowKeyboard(!showKeyboard);
                  return;
                }
                if (e === "{close}") {
                  setShowKeyboard(!showKeyboard);
                  return;
                }
                if (e === "{lang}") {
                  toggleLang();
                  return;
                }
                if (e === "{space}") {
                  if(activeField === "N")
                  setText(text + " ");
                  else
                  setText(text + " ");
                  return;
                }

                if (e === "{tab}") {
                  if(activeField === "N")
                  setText(text + "        ");
                  else
                  setPrice(price + "        ");
                  return;
                }
                if (e === "{clear}") {
                  if(activeField === "N")
                  setText("");
                  else
                  setPrice("")
                  return;
                }

                if (e === "{bksp}") {
                  if (text.length > 0) {
                    if(activeField === "N")
                    setText(text?.slice(0, -1));
                    else{
                      setPrice(price?.slice(0, -1))
                    }
                    return;
                  }
                  return;
                }
                if(lang === 1)
                {
                  // console.log(text+""+e);
                let parsed = Hangul.disassemble(text + "" + e);
                console.log(parsed);
                console.log(Hangul.assemble(["ㄲ", "ㅑ", "ㅈ"]),"99u9j9j98i");
                setText(Hangul.assemble(parsed))
                // assemble characters
                // setValue(Hangul.assemble(parsed));
                }else{
                  if(activeField === "N"){
                    setText(text + "" + e);
                  }else{
                    setPrice(price+""+e)
                  }

                }
              }}
            />
            </Motion1>
          </div>
        ) : null}
        &ensp;&ensp;
        <div className="row ml-1 justify-center align-center  ">
          <p className="text-2 mt-2" style={{ width: "60px" }}>
            {" "}
            Price &ensp;
          </p>
          &ensp;
          <input
            value={price}
            onChange={async (e) => {
              setPrice(e.target.value);
            }}
            style={{
              fontSize: 13,
              height: 35,
              border: "2px solid #777",
              paddingLeft: 10,
              fontWeight: "bold",
              fontFamily: "Arial",
              width: "100%",
            }}
            type="text"
            placeholder="Search With Price"
            onClick={() => setActiveField("P")}
            onFocus={() => setActiveField("P")}
          />
          &ensp;
          <div className="row ml-1" style={{ justifySelf: "flex-end" }}>
            <div
              className="btn bg-1 ml-1"
              onClick={prev}
              style={{ padding: 10, height: 40 }}
            >
            <FaArrowUp style={{ color: "#fff" }} />
            </div>
            <div
              className="btn bg-1 ml-1"
              onClick={next}
              style={{ padding: 10, height: 40 }}
            >
              <FaArrowDown style={{ color: "#fff" }} />
            </div>
          </div>
          {/* <KoreanKeyBoard /> */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
