import React from "react";
import CommonModal from "../../../common/components/CommonModal";

function ChooseCustomers({ show, toggle, customers, setCustomer }) {
  const renderModal = () => {
    return (
      <div className="w-100 column">
        {customers?.map((e, i) => {
          return (
            <div
              key={i}
              className="column w-100 bg-7 btn btn-lg justify-center align-left"
              style={{ marginTop: 7 }}
              onClick={() => {
                setCustomer(i);
                toggle();
                
              }}
            >
              <div className="row ">
                <div
                  className="text-1"
                  style={{ fontSize: 15, marginLeft: 10 }}
                >
                  Name :
                </div>
                <div
                  className="text-1"
                  style={{ fontSize: 15, marginLeft: 10 }}
                >
                  {e?.name}
                </div>
              </div>
              <div className="row ">
                <div
                  className="text-1"
                  style={{ fontSize: 15, marginLeft: 10 }}
                >
                  Phone:
                </div>
                <div
                  className="text-1"
                  style={{ fontSize: 15, marginLeft: 10 }}
                >
                  {e?.phone}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <CommonModal
      children={renderModal}
      show={show}
      onOk={toggle}
      ok={false}
      title="Select Customer"
      toggleModal={toggle}
    />
  );
}

export default ChooseCustomers;
