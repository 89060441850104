import React, { useState } from "react";
import { useLocation } from "react-router";
import { updateScreens } from "./multiscreen";

export const useDate = () => {
  const locale = "en";
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    }, `;

  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return {
    date,
    time,
    wish,
  };
};

export const n_format = (val) => {
  if (val < 0) {
    return "0.00";
  }
  return parseFloat(val).toFixed(2);
};


export const number_format = (number, decimals = 2, decPoint, thousandsSep) => {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  let s = ''
  const toFixedFix = function (n, prec) {
    if (('' + n).indexOf('e') === -1) {
      return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
    } else {
      const arr = ('' + n).split('e')
      let sig = ''
      if (+arr[1] + prec > 0) {
        sig = '+'
      }
      return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
    }
  }
  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

export const n_round = (val) => {
  return Math.round(val / 100) * 100;
};


export const getTens = (val) => {
  if (val < 0) {
    return "0.00";
  }
  let quotient = val / 1000; //This will give you 3
  let remainder = val % 1000;
  let quotient1 = remainder / 100; //This will give you 6
  let remainder1 = remainder % 100;
  let quotient2 = remainder1 / 10;
  return parseInt(quotient2);
};

export function emptyCache() {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      })
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export async function toggleFullScreen() {

  let fullscreenOptions = { navigationUI: "auto" };
  const screens = await updateScreens(/*requestPermission=*/true);
  if (screens.length > 1 && 1 < screens.length) {
    console.log('Info: Requesting fullscreen on another screen.');
    // TODO(msw): Use screen.id and not an index.
    fullscreenOptions.screen = screens[1];
  }


  var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

  var docElm = document.documentElement;
  if (!isInFullScreen) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen(fullscreenOptions);
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen(fullscreenOptions);
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen(fullscreenOptions);
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen(fullscreenOptions);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

export async function togglePOSFullScreen(req = false) {



  var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

  var docElm = document.documentElement;
  if (req) {
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    }
  } else {
    if (isInFullScreen && !req) {

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }
}

export const combinedItems = (arr = [], reduce = false) => {
  const res = arr.reduce((acc, obj) => {
    let found = false;
    for (let i = 0; i < acc.length; i++) {
      if (acc[i].product_id === obj.product_id) {
        found = true;
        acc[i].count++;
        if (!reduce) {
          acc[i].quantity = acc[i].quantity + obj.quantity;
        } else {
          acc[i].quantity = acc[i].quantity - obj.quantity;
        }
      };
    }
    if (!found) {
      obj.count = 1;
      acc.push(obj);
    }
    return acc;
  }, []);
  return res;
}

export const downloadFile = async (myData, fileName = new Date()) => {
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


export const randomGenerator = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const t = (text) => {
  return text;
}


export const compactText = (text, limit = 30) => {
  if (text?.length > limit) {
    return text.substring(0, limit) + "...";
  } else {
    return text
  }
}
