import React from "react";

function ButtonMd({
  title,
  bg = "bg-4",
  text = "text-1",
  height = "50px",
  bgColor = null,
  onClick,
  style = {},
}) {
  return (
    <div
      onClick={onClick}
      className={`btn-md btn row t-center justify-center align-center ${
        bgColor || bg
      }`}
      style={{ ...style, height, backgroundColor: bgColor }}
    >
      <p className={text}>{title}</p>
    </div>
  );
}

export default ButtonMd;
