import React from 'react'

function KeyboardIcon({onClick,color="white"}) {
    return (
        <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
        onClick={() => onClick()}
      >
        <path d="M5.625 11.25H8.125V13.75H5.625V11.25Z" fill={color} />
        <path d="M12.5 11.25H10V13.75H12.5V11.25Z" fill={color} />
        <path d="M14.375 11.25H16.875V13.75H14.375V11.25Z" fill={color} />
        <path d="M25.625 11.25H23.125V13.75H25.625V11.25Z" fill={color} />
        <path d="M31.875 11.25H34.375V13.75H31.875V11.25Z" fill={color} />
        <path d="M30 11.25H27.5V13.75H30V11.25Z" fill={color} />
        <path d="M18.75 11.25H21.25V13.75H18.75V11.25Z" fill={color} />
        <path d="M8.125 15.5H5.625V18H8.125V15.5Z" fill={color} />
        <path d="M10 15.5H12.5V18H10V15.5Z" fill={color} />
        <path d="M16.875 15.5H14.375V18H16.875V15.5Z" fill={color} />
        <path d="M23.125 15.5H25.625V18H23.125V15.5Z" fill={color} />
        <path d="M34.375 15.5H31.875V18H34.375V15.5Z" fill={color} />
        <path d="M27.5 15.5H30V18H27.5V15.5Z" fill={color} />
        <path d="M21.25 15.5H18.75V18H21.25V15.5Z" fill={color} />
        <path d="M5.625 20H8.125V22.5H5.625V20Z" fill={color} />
        <path d="M30 20H10V22.5H30V20Z" fill={color} />
        <path d="M31.875 20H34.375V22.5H31.875V20Z" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 6.875C1.80969 6.875 1.25 7.43462 1.25 8.125V25.625C1.25 26.3154 1.80969 26.875 2.5 26.875H37.5C38.1903 26.875 38.75 26.3154 38.75 25.625V8.125C38.75 7.43462 38.1903 6.875 37.5 6.875H2.5ZM3.125 8.75V25H36.875V8.75H3.125Z"
          fill={color}
        />
        <path
          d="M20 35.8077L14.6564 30.5756L13.4998 31.7084L20.0024 38.0756L26.4996 31.7084L25.343 30.5756L20 35.8077Z"
          fill={color}
        />
      </svg>
  
    )
}

export default KeyboardIcon

