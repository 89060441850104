import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lock from "../../common/components/Lock";
import ModalFull from "../../common/components/ModalFull";
import HomeFooter from "./components/HomeFooter";
import Navbar from "./components/Navbar";
import ProductSection from "./components/ProductSection";
import ProductsTable from "./components/ProductsTable";
import * as orderActions from "../../state/order/orderActions";
import * as customerActions from "../../state/customer/customerActions";
import * as suspendActions from "../../state/suspend/suspendActions";


function HomePageContainer() {
  const children = <h1>Hello</h1>;

  // Load Suspend Data
  const suspend = useSelector(state => state.suspend);
  const order = useSelector(state => state.order);

  const dispatch = useDispatch();

  useEffect(() => {
    if(suspend?.data?.length > 0){
      const activeSuspend = suspend?.data?.find(e => e.id === suspend?.activeId);
      console.log(activeSuspend,"909090");
      console.log(suspend?.activeId);
      if(activeSuspend){
        dispatch(orderActions.fillOrder(activeSuspend?.order));
        dispatch(customerActions.fillCustomer(activeSuspend?.customer));
        dispatch(suspendActions.deleteSuspend(suspend?.activeId));
      }
    }
  }, [suspend.activeId]);

  

  return (
    <div className="home-container">

      <Navbar />
      <ProductSection />
      <ProductsTable />
      <HomeFooter />
      <ModalFull children={children} />
      <Lock />
    </div>
  );
}

export default HomePageContainer;
