import { n_format } from '../../utils/common'

const initialState = {
	products: [],
	discount: '0.000',
	selected_product: 0,
	selected_product_item: {},
	customer_id: null,
	invoice: {
		id: Math.floor(Math.random() * 90000) + 10000,
		payBy: 'CASH',
		return: false,
	},
	user_id: null,
	printed: false,
}

const SET_ORDER_PRODUCTS = 'SET_ORDER_PRODUCTS'
const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'
const DELETE_PRODUCT = 'DELETE_PRODUCT'
const EMPTY_ORDER = 'EMPTY_ORDER'
const SET_DISCOUNT = 'SET_DISCOUNT'
const SET_CUSTOMER = 'SET_CUSTOMER'
const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
const SET_USER = 'SET_USER'
const REMOVE_USER = 'REMOVE_USER'
const SET_INVOICE = 'SET_INVOICE'
const FILL_ORDER = 'FILL_ORDER'
const TOGGLE_TENDER = 'TOGGLE_TENDER'
const SET_PRINTED = 'SET_PRINTED'
const FILL_ORDERS = 'FILL_ORDERS'

const orderReducer = (state = initialState, action) => {
	switch (action.type) {
		case FILL_ORDERS:
			return {
				...state,
				orders: [
					...action.payload.map((e, i) => {
						return { ...e, edited: false }
					}),
				],
			}
		case SET_ORDER_PRODUCTS:
			let is_new = true
			let newSame = false
			let newBarcode = action.payload?.barcode
			// Updating Existing Array Of Products
			const products = state.products.map((e, i) => {
				const discount_percent = n_format(action?.payload?.discount_percent)
				if ((e.barcode === action.payload.barcode && e.price === action.payload.price) || (e.barcode === action.payload.barcode + '-' + action.payload.price && e.price === action.payload.price)) {
					is_new = false
					// Old Product Updation
					return {
						...e,
						discount_percent,
						quantity: action.payload.quantity || e.quantity + +1,
						price: action.payload.price || e.price,
					}
				} else if (e.barcode === action.payload.barcode && e.price !== action.payload.price) {
					is_new = false
					// Old Product Updation
					newSame = true
					if (e.barcode == action.payload.barcode + '-' + action.payload.price && e.price === action.payload.price) {
						newSame = false
					}
					console.log(e.barcode)
					newBarcode = action.payload.barcode?.toString()?.split('-')[0] + '-' + Math.floor(Math.random() * 1000) + (+i + +1)
					console.log(newBarcode, 'jkjkk')
					return {
						...e,
						name_en: action.payload.name_en?.toString()?.split('-')[0] + '-' + Math.floor(Math.random() * 1000) + +(+i + +1),
						barcode: newBarcode,
						discount_percent,
						quantity: action.payload.quantity || e.quantity,
						price: action.payload.price || e.price,
					}
				} else {
					return { ...e }
				}
			})

			if (is_new) {
				if (newSame) {
					newBarcode = action.payload.barcode?.toString()?.split('-')[0] + '-' + Math.floor(Math.random() * 1000) + (+products.length + +1)
					console.log(newBarcode, 'jkjkk')

					products.push({
						...action.payload,
						name_en: action.payload.name_en?.toString()?.split('-')[0] + '-' + Math.floor(Math.random() * 1000) + (+products.length + +1),
						barcode: newBarcode,
						price: action.payload.price,
						quantity: action.payload.quantity || 1,
					})
				} else {
					products.push({
						...action.payload,
						price: action.payload.price,
						quantity: action.payload.quantity || 1,
					})
				}
			}
			console.log(newBarcode)
			console.log(action.payload?.price)
			console.log(products)
			const selected_item = products.filter((e, i) => e?.barcode === newBarcode && e?.price === action.payload?.price)

			return {
				...state,
				products,
				selected_product: selected_item[0]?.barcode,
				selected_product_item: {
					...selected_item[0],
				},
			}
		case SET_SELECTED_PRODUCT:
			return {
				...state,
				selected_product_item: action.payload,
				selected_product: action.payload?.barcode,
			}
		case EMPTY_ORDER:
			return {
				...state,
				products: [],
				discount: '0.00',
				selected_product: 0,
				selected_product_item: {},
				customer_id: null,
				tender: false,
				invoice: {
					id: Math.floor(Math.random() * 90000) + 10000,
					payBy: 'CASH',
					return: false,
				},
				user_id: null,
				printed: false,
			}
		case FILL_ORDER:
			return {
				...action.payload,
			}
		case DELETE_PRODUCT:
			console.log(action.payload)
			const newOrderProducts = state.products.filter((e, i) => {
				if (action.payload !== e.barcode) {
					return true
				} else {
					return false
				}
			})
			return {
				...state,
				products: newOrderProducts,
				selected_product_item: {},
				selected_product: 0,
			}
		case SET_DISCOUNT:
			return {
				...state,
				discount: n_format(action.payload),
			}
		case SET_CUSTOMER:
			return {
				...state,
				customer_id: action.payload,
			}
		case REMOVE_CUSTOMER:
			return {
				...state,
				customer_id: null,
			}
		case SET_USER:
			return {
				...state,
				user_id: action.payload,
			}
		case REMOVE_USER:
			return {
				...state,
				user_id: null,
			}
		case TOGGLE_TENDER:
			return {
				...state,
				tender: !state.tender,
			}
		case SET_INVOICE:
			return {
				...state,
				invoice: {
					...state.invoice,
					...action.payload,
				},
			}
		case SET_PRINTED:
			return {
				...state,
				printed: action.payload,
			}
		default:
			break
	}
	return state
}

export default orderReducer
