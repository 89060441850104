import React from 'react'

function ButtonLg({title,bg="bg-4",text="text-1",value,onClick,style,textStyle,titleSize}) {
    return (
        <div onClick={onClick} style={{...style}} className={`btn-lg btn column t-center justify-center align-center ${bg}`}>
            {value ? <p style={{...textStyle}} className={text}>{value}</p>:null}
           <p className={text} style={{fontSize:titleSize}}>{title}</p>
        </div>
    )
}

export default ButtonLg
