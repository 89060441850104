const initialState = [];


const SET_CATEGORIES = 'SET_CATEGORIES';


const categoryReducer =(state =initialState,action) => {
    switch (action.type) {
        case SET_CATEGORIES:    
            return action.payload
            default:
            break;
        }
          return state;
}

export default categoryReducer;