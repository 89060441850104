import React from "react";
import ModalHeader from "./ModalHeader";

function CommonModal({
  children,
  header,
  show,
  toggleModal,
  onOk,
  full,
  title,
  bottomOptions,
  handleBack,
  style = {},
  ok = true,
  cancel = true,
  back =false,
  title_1 = "Ok",
  title_2 = "Cancel",
  title_3 = "Back",
  isSaving = false
}) {
  return (
    <div
      className={show ? (full ? `modal-full-lg` : `modal-full`) : `modal-hide`}
      style={{ ...style }}
    >
      {!full ? (
        <div className="modal-body">
          {header ? <h4 className="text-1 t-center">{header}</h4> : null}
          <div className="w-100">
            {!bottomOptions ? (
              <ModalHeader
                ok={ok}
                cancel={cancel}
                back={back}
                title_1={title_1}
                title_2={title_2}
                isSaving={isSaving}
                title_3={title_3}
                handleOk={onOk}
                handleBack={handleBack}
                title={title}
                handleCancel={toggleModal}
              />
            ) : null}
            <br />
            <div className="row justify-center w-100">{children()}</div>
            {bottomOptions ? (
              <ModalHeader
                ok={ok}
                cancel={cancel}
                back={back}
                title_1={title_1}
                title_2={title_2}
                title_3={title_3}
                isSaving={isSaving}
                handleOk={onOk}
                handleBack={handleBack}
                title={title}
                handleCancel={toggleModal}
              />
            ) : null}
          </div>
        </div>
      ) : (
        children()
      )}
    </div>
  );
}

export default CommonModal;
