import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import TableRow from './TableRow'

function ProductsTable({isCustomer}) {
    const order = useSelector(state => state.order);
    const products = useSelector(state => state.products);
    const active_category = useSelector(state => state.common.active_category);
    const favProducts = products.filter(e => e.category_id === active_category ).length;
    const mt = favProducts > 0;
    const productRef = useRef(null);

    useEffect(() => {
        let index = 0;
        order.products.forEach((e,i) => {
            if(order.selected_product === e?.barcode){
                index = i;
                return;
            }    
        });
        productRef.current.scrollTop =  index *40;
    },[order.selected_product])


    return (
        <>
        <div className="table-header row bg-1 align-center" style={{marginTop:!mt?120:0}}>
            <div className="w-20  "><p className="text-1 t-center" style={{fontSize:22}}>No.</p></div>
            <div className="w-100  "><p className="text-1 t-center" style={{fontSize:22}}>Description</p></div>
            <div className="w-30  "><p className="text-1 t-center" style={{fontSize:22}}>Qty</p></div>
            <div className="w-30  "><p className="text-1 t-center" style={{fontSize:22}}>Price</p></div>
            <div className="w-30  "><p className="text-1 t-center" style={{fontSize:22}}>Disc($)</p></div>
            <div className="w-30 "><p className="text-1 t-center" style={{fontSize:22}}>Total</p></div>
        </div>
        <div className="product-table" style={{maxHeight:"80vh"}} ref={productRef}>
            
            {
                order.products.map((e,i) => {
                    return (
                        <TableRow customer={isCustomer} item={e} key={i} index={i} first={i === 0}  />
                    )
                })
            }
            
         
        </div>
        </>
    )
}

export default ProductsTable
