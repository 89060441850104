import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import ProductsTable from "./components/ProductsTable";
import { Link, useParams } from "react-router-dom";
import InvoiceService from "../../api/InvoiceAPI";
import CustomerService from "../../api/CustomerAPI";
import InvoiceModal from "./components/InvoiceModal";
import CommonModal from "../../common/components/CommonModal";
import ChangeQuantity from "./components/ChangeQuantity";
import { combinedItems, n_format } from "../../utils/common";
import ConfirmModal from "../ReturnPage/components/ConfirmModal";
import { FaArrowLeft } from "react-icons/fa";

function ReturnPageContainer() {
  const invoice = useSelector((state) => state.invoice);

  const { option } = useParams();

  // Confirm Popup
  const [showConfirm, setShowConfirm] = useState(false);
  const [products, setProducts] = useState([]);
  const [showQuickConfirmReturn, setShowQuickConfirmReturn] = useState(false);
  const [showChangeQuantity, setShowChangeQuantity] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderPoints, setOrderPoints] = useState("0.00");
  const [calAmount, setCalAmount] = useState("0.00");
  const [amountToReturn, setAmountToReturn] = useState("0.00");
  const [discountReturn, setDiscountReturn] = useState("0.00");
  const [grandTotalReturn, setGrandTotalReturn] = useState("0.00");
  const [returnPoints, setReturnPoints] = useState("0.00");
  const [customerData, setCustomerData] = useState({});
  const [redeemedPoints, setRedeemedPoints] = useState(0)


  const getCustomerData = async () => {
    const data = await CustomerService.getCustomer(invoice?.activeInvoice?.customer?.phone);
    if (data.length > 0) {
      setCustomerData(data[0])
    }
  }

  const toggleChangeQuantity = (e) => {
    setSelectedProduct(e);
    setShowChangeQuantity(!showChangeQuantity);
  };

  const toggleQuickConfirmReturn = (id) => {
    setCurrentId(id);
    setShowQuickConfirmReturn(!showQuickConfirmReturn);
  };

  const setOrderAmount = () => {
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    invoice?.activeInvoice?.order?.forEach((e) => {
      const oldTotal = n_format(e.quantity) * n_format(e.price);
      const discount =
        (n_format(oldTotal) * n_format(e.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = pureTotalAmount + total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = pureQuantity + e.quantity;
      purePoints = +purePoints + (e.quantity * e.price * (e?.point || 0)) / 100;
    });

    setOrderPoints(purePoints);
    setCalAmount(n_format(pureTotalAmount));
  };

  const onOkQuickConfirmReturn = () => {
    toggleInvoice(toggleConfirm);
    setShowQuickConfirmReturn(!showQuickConfirmReturn);
  };

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const [showInvoice, setShowInvoice] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [completeData, setCompleteData] = useState(null);

  const toggleInvoice = (toggleConfirmParam = () => { }) => {
    setShowInvoice(!showInvoice);
    toggleConfirmParam && toggleConfirmParam();
  };

  const setData = () => {
    const products2 = [
      ...invoice?.activeInvoice?.order?.map((e, i) => {
        return {
          ...e,
          // name_en: e?.product?.name_en,
          item_code: e?.product?.item_code,
          barcode: e?.product?.barcode,
          update: null,
          item: null,
        };
      }),
    ];
    console.log(products2);
    if (invoice?.activeInvoice?.return_invoice?.length > 0) {
      const returnproducts2 = [];
      invoice?.activeInvoice?.return_invoice?.forEach(e => {
        returnproducts2.push(e?.order?.map((e, i) => {
          return {
            ...e,
            // name_en: e?.product?.name_en,
            item_code: e?.product?.item_code,
            barcode: e?.product?.barcode,
            update: null,
            item: null,
          };
        }));
      })

      const returnproducts3 = combinedItems(returnproducts2.flat(), false)
      console.log(returnproducts3, "returned products");
      const finalProducts = [...products2, ...returnproducts3];
      const finalProducts2 = combinedItems(finalProducts, true);


    } else {
      console.log("Not Returned");
    }



    setProducts([...products2]);
  };

  useEffect(() => {
    getCustomerData();
    setData();
    setOrderAmount();


  }, []);

  useEffect(() => {
    syncData();
  }, [products]);

  const syncData = () => {
    let totalAmount = 0;
    // const discountPerProduct =
    //   (invoice?.activeInvoice?.total_discount * calAmount) /
    //   100 /
    //   products?.length;
    let returnDiscount = 0;
    let points = 0;
    let product_count = 0;
   let return_product_count = 0;
    products?.forEach((e, i) => {
      if (e?.update !== null) {
        // Delete Product
        const product = e?.item;
        const amount = n_format(
          product?.quantity * product?.price -
          (product?.quantity * product?.price * product?.discount_percent) /
          100
        );
        totalAmount = +totalAmount + +amount;

        const discount =
          (invoice?.activeInvoice?.total_discount * amount) / 100;
        returnDiscount = +returnDiscount + +discount;
        points = +points + (product.quantity * e.price * (e?.point || 0)) / 100;

        return_product_count = return_product_count + parseInt(product.quantity ?? 0);
      }
    });
    invoice?.activeInvoice?.order?.forEach((e) => {
      product_count = product_count + e.quantity;
    });
    setDiscountReturn(n_format(returnDiscount));
    const grandTotal = n_format(totalAmount - returnDiscount);
    const [totalGrandTotal, redeemedPoints] = getGrandTotal(grandTotal,product_count,return_product_count);
    setAmountToReturn(n_format(totalGrandTotal));
    setGrandTotalReturn(n_format(totalGrandTotal));
    setRedeemedPoints(redeemedPoints)
    setReturnPoints(points);
  }


  // const getGrandTotal = (total,product_count,return_product_count) => {
  //   console.log(total,product_count,return_product_count)
  //   const redeemedPoints = parseFloat(invoice?.activeInvoice?.redeem_amount ?? 0);
  //   const point_per_count =   redeemedPoints / product_count ;
  //   const redeem_points = point_per_count * return_product_count

  //   return [total - redeemedPoints, redeem_points]
    
  // }


    const getGrandTotal = (total,product_count,return_product_count) => {
    
    const redeemedPoints = parseFloat(invoice?.activeInvoice?.redeem_amount ?? 0);
    const point_per_count =   redeemedPoints / product_count ;
    const redeem_points = point_per_count * return_product_count;
    if(total <= invoice?.activeInvoice?.amount){
      return [total , 0]
    }else{
      return [invoice?.activeInvoice?.amount, redeemedPoints -(parseFloat(calAmount) - total) ]
    } 

   
    
  }

  const partialInvoiceReturn = () => {
    const selected = invoice?.activeInvoice;
    const id = selected?.id;
    const updatedInvoice = {
      ...selected,
      uniq_id: selected?.uniq_id,
      payBy: selected?.pay_by,
      amount: selected?.amount,
      redeemAmount: selected?.redeem_amount,
      dinomAmount: selected?.dinom_amount,
      return: true,
    };
    const user_id = selected?.user_id;
    const customer_id = selected?.customer?.id;

    const customer = {
      data: {
        ...customerData,
      },
    };

    const discount = selected?.total_discount;

    const updatedProducts = [
      ...products
        ?.filter((e) => e?.update)
        ?.map((e, i) => {
          if (e?.update !== null) {
            return {
              ...e?.item,
              // name_en: e?.item?.name_en,
              item_code: e?.item?.item_code,
              barcode: e?.item?.barcode,
            };
          }
        }),
    ];

    const order = {
      products: updatedProducts,
      discount,
      customer_id,
      invoice: updatedInvoice,
      user_id,
    };

    const completeData = {
      customer,
      order,
      id,
      amountToReturn,
      discountReturn,
      grandTotalReturn,
      returnPoints,
      redeemAmount: redeemedPoints
    };


    setCompleteData({ ...completeData });

    toggleInvoice();
  };

  const deleteProduct = (id) => {
    const data = products.map((e) => {
      if (e.id === id) {
        if (e?.update === "0") {
          return {
            ...e,
            update: null,
            item: null,
          };
        }
        return {
          ...e,
          update: "0",
          item: e,
        };
      }
      return e;
    });

    setProducts([...data]);
  };

  const changeQuantity = (quantity) => {
    const data = products.map((e) => {
      if (e.id === selectedProduct?.id) {
        if (selectedProduct.quantity === quantity) {
          return {
            ...e,
            update: null,
            item: null,
          };
        }
        return {
          ...e,
          update: "1",
          item: {
            ...selectedProduct,
            quantity: selectedProduct.quantity - quantity,
          },
        };
      }
      return e;
    });

    setProducts([...data]);
    setShowChangeQuantity(!showChangeQuantity);
  };


  const quickReturn = () => {
    const data = products.map((e) => {

      return {
        ...e,
        update: "0",
        item: e,
      };

    });

    let totalAmount = 0;
    let returnDiscount = 0;
    let points = 0;
    data?.forEach((e, i) => {
      if (e?.update !== null) {
        // Delete Product
        const product = e?.item;
        const amount = n_format(
          product?.quantity * product?.price -
          (product?.quantity * product?.price * product?.discount_percent) /
          100
        );
        totalAmount = +totalAmount + +amount;

        const discount =
          (invoice?.activeInvoice?.total_discount * amount) / 100;
        returnDiscount = +returnDiscount + +discount;
        points = +points + (e.quantity * e.price * (e?.point || 0)) / 100;
      }
    });


    const selected = invoice?.activeInvoice;
    const id = selected?.id;
    const updatedInvoice = {
      ...selected,
      uniq_id: selected?.uniq_id,
      payBy: selected?.pay_by,
      amount: selected?.amount,
      redeemAmount: selected?.redeem_amount,
      dinomAmount: selected?.dinom_amount,
      return: true,
    };
    const user_id = selected?.user_id;
    const customer_id = selected?.customer?.id;

    const customer = {
      data: {
        ...selected?.customer,
      },
    };

    const discount = selected?.total_discount;

    const updatedProducts = [
      ...data
        ?.filter((e) => e?.update)
        ?.map((e, i) => {
          if (e?.update !== null) {
            return {
              ...e?.item,
              // name_en: e?.item?.name_en,
              item_code: e?.item?.item_code,
              barcode: e?.item?.barcode,
            };
          }
        }),
    ];

    const order = {
      products: updatedProducts,
      discount,
      customer_id,
      invoice: updatedInvoice,
      user_id,
    };


    const completeData = {
      customer,
      order,
      id,
      amountToReturn: n_format(totalAmount),
      discountReturn: n_format(returnDiscount),
      grandTotalReturn: n_format(totalAmount - returnDiscount - (invoice?.activeInvoice?.redeem_amount ?? 0)),
      returnPoints: points,
      redeemAmount: order?.invoice?.redeem_amount
    };

    setCompleteData({ ...completeData });

    toggleInvoice();


    toggleInvoice(toggleConfirm);

    //toggleInvoice(toggleConfirm);
    // setTimeout(() => {
    //   window.print();
    // }, 100);


  }
  return (
    <div className="home-container bg-10">
      <div className="modal-header-lg row bg-1 align-center justify-center">
        <h3 style={{ color: "var(--white)" }}>{option === "manual" ? "Manual Return Invoice" : "Quick Return Invoice"}</h3>
      </div>
      <div className="modal-actions-lg row justify-center mb-1 ">
        {amountToReturn !== "0.00" || option !== "manual" ?
          <div
            onClick={() => {
              if (option === "quick") {
                quickReturn();
                setTimeout(() => {
                  window.print();
                }, 100);
              } else {

                partialInvoiceReturn();
                toggleInvoice(toggleConfirm);
                setTimeout(() => {
                  window.print();
                }, 100);
              }
            }}
            className="modal-btn-lg bg-6 text-1"
          >
            Print
          </div>
          : null}
        <Link to="/return" className="modal-btn-lg bg-1 text-1">
          <FaArrowLeft color="#fff" /> &nbsp;Back
        </Link>
      </div>

      <div className="mt-2">
        <div
          style={{ display: "inline-block" }}
          className="modal-btn-lg bg-4 text-1 "
        >
          Total ($): ${n_format(calAmount)}
        </div>
        <div
          style={{ display: "inline-block" }}
          className="modal-btn-lg bg-7 text-1"
        >
          Discount On Order ({invoice?.activeInvoice?.total_discount}%): $
          {n_format((invoice?.activeInvoice?.total_discount * calAmount) / 100)}
        </div>
        {invoice?.activeInvoice?.customer_id ?
          <div
            style={{ display: "inline-block" }}
            className="modal-btn-lg bg-6 mt-1 text-1"
          >
            Redeem Points: ${n_format(invoice?.activeInvoice?.redeem_amount ?? 0)}
          </div>
          : null}

        <div
          style={{ display: "inline-block" }}
          className="modal-btn-lg bg-1 text-1 mb-1"
        >
          Grand Total ($): ${n_format(invoice?.activeInvoice?.amount)}
        </div>
        {customerData?.name ?
          <div
            style={{ display: "inline-block" }}
            className="modal-btn-lg bg-1 text-1 mb-1"
          >
            Customer  : {customerData?.name}
          </div>
          : null}
        <br />
        <br />
        {invoice?.activeInvoice?.customer_id ?
          <div
            style={{ display: "inline-block" }}
            className="modal-btn-lg bg-6 text-1 mt-1"
          >
            Order Points : {n_format(orderPoints)}
          </div>
          : null}
      </div>
      <br />
      <hr />

      <div className="mt-2">
        <div
          style={{ display: "inline-block" }}
          className="modal-btn-lg bg-6 mt-1 text-1"
        >
          Amount To Return : ${n_format(amountToReturn)}
        </div>
        <div
          style={{ display: "inline-block" }}
          className="modal-btn-lg bg-6 mt-1 text-1"
        >
          Discount: ${n_format(discountReturn)}
        </div>
        {customerData?.name ?
          <div
            style={{ display: "inline-block" }}
            className="modal-btn-lg bg-6 mt-1 text-1"
          >
            Return Points: ${n_format(returnPoints  + redeemedPoints)}
          </div>
          : null}
        <div
          style={{ display: "inline-block" }}
          className="modal-btn-lg bg-6 mt-1 text-1"
        >
          Grand Total To Return : ${n_format(grandTotalReturn)}
        </div>
      </div>

      {option === "manual" ?
        <ProductsTable
          products={products}
          deleteProduct={deleteProduct}
          changeQuantity={changeQuantity}
          toggleChangeQuantity={toggleChangeQuantity}
        />
        : null}
      <ConfirmModal
        toggle={toggleConfirm}
        toggleInvoice={toggleInvoice}
        show={showConfirm}
        returnId={currentId}
        completeData={completeData}
      //  getInvoices={getInvoices}
      />

      <CommonModal
        children={() => { }}
        show={showQuickConfirmReturn}
        onOk={onOkQuickConfirmReturn}
        title={
          <p style={{ textAlign: "center" }}>
            Confirm Quick Return ?
            <br />
          </p>
        }
        toggleModal={toggleQuickConfirmReturn}
      />
      <ChangeQuantity
        show={showChangeQuantity}
        toggle={toggleChangeQuantity}
        onOk={changeQuantity}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <InvoiceModal
        toggle={toggleInvoice}
        show={showInvoice}
        completeData={completeData}
      />
    </div>
  );
}

export default ReturnPageContainer;
