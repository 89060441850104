import React from "react";

function NumberBtn({ title, fontSize,up,down,onClick }) {
  return (
    <div onClick={onClick} className={`num-btn bg-1 justify-center align-center row text-1 ${up && 'up'}  ${down && 'down'}` }>
      <p style={{ fontSize, wordBreak: "break-word" }}> {title}</p>
    </div>
  );
}

export default NumberBtn;
