import React from 'react'
import Categories from './Categories'
import ProductsTray from './ProductsTray'
import BagProduct from '../../../common/components/BagProduct'


function ProductSection() {

   
    return (
        <div className="row w-100">
                <BagProduct />
            <div className="w-100">
                <Categories />
                <ProductsTray />
            </div>
        </div>
    )
}

export default ProductSection
