import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import TableRow from './TableRow'

function ProductsTable({isCustomer}) {
    const order = useSelector(state => state.order);
    const products = useSelector(state => state.products);
    const active_category = useSelector(state => state.common.active_category);
    const favProducts = products.filter(e => e.category_id === active_category ).length;
    const mt = favProducts > 0;
    const productRef = useRef(null);

    useEffect(() => {
        let index = 0;
        order.products.forEach((e,i) => {
            if(order.selected_product === e?.barcode){
                index = i;
                return;
            }    
        });
        productRef.current.scrollTop =  index *40;
    },[order.selected_product])


    return (
        <>
        <div className="table-header row bg-1 align-center" style={{marginTop:!mt?120:0}}>
                <div className="s-no "><p className="text-1 t-center">No.</p></div>
                <div className="description "><p className="text-1 t-center">Description</p></div>
                <div className="barcode "><p className="text-1 t-center">Barcode</p></div>
                <div className="qty "><p className="text-1 t-center">Qty</p></div>
                <div className="price "><p className="text-1 t-center">Price</p></div>
                <div className="disc-p "><p className="text-1 t-center">Disc(%)</p></div>
                <div className="disc-d "><p className="text-1 t-center">Disc($)</p></div>
                <div className="total "><p className="text-1 t-center">Total</p></div>
                {!isCustomer ? <div className="del "><p className="text-1 t-center">Action</p></div> : null}
        </div>
        <div className="product-table" ref={productRef}>
            
            {
                order.products.map((e,i) => {
                    return (
                        <TableRow customer={isCustomer} item={e} key={i} index={i} first={i === 0}  />
                    )
                })
            }
            
         
        </div>
        </>
    )
}

export default ProductsTable
