import React from "react";
import { FaArrowDown, FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ButtonMd from "../../../common/components/ButtonMd";
import ButtonSm from "../../../common/components/ButtonSm";
import * as invoiceActions from "../../../state/invoice/invoiceActions";
import { n_format } from "../../../utils/common";

function TableRow({ e, deleteProduct, toggleChangeQuantity, changeQuantity }) {
  const dispatch = useDispatch();

  return (
    <div
      className={`border-all mt-1 mb-1 ${
        e.update === "0" ? "bg-4" : e.update === "1" ? "bg-11" : ""
      } `}
    >
      <div
        className={`row`}
       
      >
        <div className="w-100 ">
          <div className="btn t-header p-1 row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">{e.name_en?.slice(0,10)} </span>
          </div>
        </div>
        <div className="w-100 ">
          <div className="btn t-header p-1 row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">{e.barcode} </span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">{e.quantity}</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">
              {e.update === "1"
                ? e?.item?.quantity
                : e.update === "0"
                ? e?.quantity
                : 0}
            </span>
          </div>
        </div>
        <div className="w-60 ">
          <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">$ {n_format(e.price)}</span>
          </div>
        </div>
        <div className="w-60 ">
          <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">{n_format(e.discount_percent)}</span>
          </div>
        </div>
       
        <div className="w-60 ">
          <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">$ {n_format((e.price * e.quantity) - (e.price * e.quantity * e.discount_percent/100))}</span>
          </div>
        </div>
        <div className="w-60 ">
          <div className="btn t-header row justify-center align-center t-left border-bg-4 ">
            <span className="text-4 ">{n_format(e.price * e.quantity * e.point/100)}</span>
          </div>
        </div>
        <div
          className="w-60 "
          onClick={() => {
            // setReturnInvoice(e?.id);
          }}
        >
          <ButtonMd
            bg="bg-6"
            title="Change Qty"
            textStyle={{ fontSize: 11 }}
            onClick={() => toggleChangeQuantity(e)}
            icon={<FaArrowDown color="#fff" />}
          />
        </div>

        <div
          className="w-60 "
          onClick={() => {
            // partialInvoiceReturn(e);
          }}
        >
          {e.update === "0" ? (
            <ButtonSm
              title="Add"
              onClick={() => deleteProduct(e.id)}
              icon={<FaPlus color="#fff" />}
            />
          ) : (
            <ButtonSm
              title="Delete"
              onClick={() => deleteProduct(e.id)}
              icon={<FaTrash color="#fff" />}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TableRow;
