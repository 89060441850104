
const initialState = {
    data:[],
    activeId:0
  };
  
  const ADD_SUSPEND = "ADD_SUSPEND";
  const EMPTY_SUSPEND = "EMPTY_SUSPEND";
  const ACTIVATE_SUSPEND = "ACTIVATE_SUSPEND";
  const DELETE_SUSPEND = "DELETE_SUSPEND";
  
  const suspendReducer = (state = initialState, action) => {
    switch (action.type) {
    case ADD_SUSPEND:
          console.log(action.payload,'1');
        return {
          ...state,
          data:[...state.data,{...action.payload,date:new Date().toLocaleString()}]
        };
    case ACTIVATE_SUSPEND:
          return {
            ...state,
            activeId:action.payload
          };
      
    case DELETE_SUSPEND: 
    const newData2 = state.data.filter((e,i) => e.id !== action.payload);
      return {
          ...state,
          data:[...newData2]
      }
    case EMPTY_SUSPEND: 
      return {
          ...state,
          data:[]
      }
      default:
        break;
    }
    return state;
  };
  
  export default suspendReducer;
  