import React, { useEffect, useState } from "react";
import CommonModal from "../../../common/components/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import { n_format, n_round } from "../../../utils/common";
import InvoiceService from "../../../api/InvoiceAPI";
import "../../../invoice.css";

function InvoiceModal({ show, toggle,invoice }) {
  const order = useSelector((state) => state.order);
  const common = useSelector((state) => state.common);
  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.user);

  const USD_RATE = common?.currencies[1];

  const [calAmount, setCalAmount] = useState("0.00");

  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [orderPoints, setOrderPoints] = useState(0.0);
  const [uniqId, setUniqId] = useState("")


  const dispatch = useDispatch();
  

  useEffect(() => {
    setOrderAmount();
  }, [order.products, show]);

  const setOrderAmount = async () => {
    console.log(invoice,"9090909090");
    let pureTotalAmount = 0.0;
    let pureQuantity = 0.0;
    let purePoints = 0.0;
    invoice?.order?.forEach((e) => {
      const oldTotal = n_format(e.quantity) * n_format(e.price);
      const discount =
        (n_format(oldTotal) * n_format(e.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = pureTotalAmount + total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = pureQuantity + e.quantity;
      purePoints = +purePoints + (e.quantity * e.price * (e?.point || 0)) / 100;
    });
  
    setOrderPoints(purePoints);
    setCalAmount(n_format(pureTotalAmount) - n_format(invoice?.total_discount));
    const invoiceId =invoice?.lastRInvoiceId;
    setUniqId(invoiceId)
  };

  const onOk = () => {
    // On CLosed
    back();
  };

  const back = () => {
    // TODO Reset and then back
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    toggle();
  };

 

  useEffect(() => {

    setTimeout(() => {
      if (show){ 
          toggle();
        
        };
      console.log("123");
    }, 100);
  }, [show]);

 

  const renderModal = () => {
    return (
      <>
        <div className="slip section-to-print">
          <div>
            <div>
              <div>
                <div />
                <div className="text_center f_13">
                  <div>
                    <span className="pb_3 f_16">
                      <b>{invoice?.branch?.name_kh || user?.data?.branch?.name_kh}</b>
                    </span>
                  </div>
                  <div className="arial invh3">
                    {invoice?.branch?.name_en || user?.data?.branch?.name_en}
                  </div>
                  <div>{invoice?.branch?.address ||user?.data?.branch?.address}</div>
                  {invoice?.branch?.license || user?.data?.branch?.license ? (
                    <div>{invoice?.branch?.license || user?.data?.branch?.license}</div>
                  ) : null}
                  <div>Tel:{invoice?.branch?.phone || user?.data?.branch?.phone}</div>
                  <div className="invh3 my_2">
                    {invoice?.branch?.subtitle || user?.data?.branch?.subtitle}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div />
                    <div class="borderline"></div>
                    <span>
                      <table className="w_100">
                        <tbody>
                          <tr>
                            <td>Cashier </td>
                            <td>:{invoice?.user?.name || user?.data?.name}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>Time </td>
                            <td align="right">
                              :{new Date().toLocaleTimeString()}
                            </td>
                          </tr>
                          <tr>
                            <td>Customer </td>
                            <td>
                              :
                              {invoice?.customer?.name || customer?.data?.name
                                ? invoice?.customer?.name  || customer?.data?.name
                                : "Walk in"}
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>Date</td>
                            <td align="right">
                              {" "}
                              :{new Date().toLocaleDateString()}
                            </td>
                          </tr>
                          <tr>
                            <td>Invoice No </td>
                            <td>
                              :{uniqId}
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>Shop </td>
                            <td align="right">
                              :{invoice?.branch?.location  || user?.data?.branch?.location}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                  <hr className="solid"></hr>
                  <div>
                    <div />
                    <span>
                      <table className="w_100 f_13">
                        <tbody>
                          <tr>
                            <td width={26} className="invh4">
                              No
                            </td>
                            <td width={136} className="invh4">
                              Description
                            </td>
                            <td width={26} align="center" className="invh4">
                              Qty
                            </td>
                            <td width={46} align="center" className="invh4">
                              Price
                            </td>
                            <td width={46} align="center" className="invh4">
                              Dis.
                            </td>
                            <td width={56} align="right" className="invh4">
                              Amount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </div>
                </div>
              </div>
              <div />
              <hr className="solid"></hr>
              <div className="f_13">
                {invoice?.order?.map((item, index) => {
                  return (
                    <div>
                      <table className="w_100 ">
                        <tbody>
                          <tr>
                            <td width={26} className="invh4">
                              {index + 1}
                            </td>
                            <td width={136} className="invh4">
                            { item?.name_en?.slice(0, 9) ??  item?.product?.name_en?.slice(0, 9)}
                            </td>
                            <td width={26} align="center" className="invh4">
                              {item?.quantity}
                            </td>
                            <td width={46} align="center" className="invh4">
                              {n_format(item?.price)}
                            </td>
                            <td width={46} align="center" className="invh4">
                              (-{n_format((item?.discount_percent * item?.price * item?.quantity /100))})
                            </td>
                            <td width={56} align="right" className="invh4">
                              {" "}
                              {n_format(item?.price * item?.quantity - (item?.discount_percent * item?.price * item?.quantity /100))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>

              <hr className="solid"></hr>
              <div>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td>សរុប ($) Sub Total ($) :</td>
                        <td align="right" className="invh2">
                         ($ {n_format(invoice?.amount + +invoice?.total_discount)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td>បញ្ចុះតំលៃ Discount (%) :</td>
                        <td align="right" className="invh2">
                         (-${n_format(invoice?.total_discount)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td className="invh3">
                          សរុបចុងក្រោយ ($) Grand Total ($):
                        </td>
                        <td align="right" className="invh3">
                          ($ {n_format(invoice?.amount)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td className="invh3">
                          សរុបចុងក្រោយ (៛) Grand Total (៛):
                        </td>
                        <td align="right" className="invh3">
                          (៛ {n_round((invoice?.amount) * USD_RATE?.value)})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td>ប្រាក់ទទួល($) PaidAmount ($):</td>
                        <td align="right" className="invh2">
                          $ {n_format(invoice?.dinom_amount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td>ប្រាក់អាប់ ($) Change ($):</td>
                        <td align="right" className="invh2">
                          ${" "}
                          {n_format(
                            +invoice?.dinom_amount -
                              +invoice?.amount +
                              +invoice?.redeem_amount
                          )}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100 my_2">
                    <tbody>
                      <tr>
                        <td>ប្រាក់អាប់ (៛) Change (៛):</td>
                        <td align="right" className="invh2">
                          ៛{" "}
                          {n_round((
                           +invoice?.dinom_amount -
                           +invoice?.amount +
                           +invoice?.redeem_amount
                          ) * USD_RATE?.value)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span>
                  <table className="w_100">
                    <tbody>
                      <tr>
                        <td>
                          Customer ID :{" "}
                          {invoice?.customer?.phone
                            ? "***" +
                            invoice?.customer?.phone 
                            ?.toString()
                                ?.slice(
                                  invoice?.customer?.phone?.toString()?.length - 4
                                )
                          
                                
                            : "Walk in"}
                        </td>
                        {invoice?.customer?.phone ? (
                          <td align="right">
                            Customer Name :{" "}
                            {invoice?.customer?.name
                              ? invoice?.customer?.name
                              : "Walk in"}
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                </span>
                {invoice?.customer?.phone? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td>Gain Point : ({n_format(invoice?.order_point)}) </td>
                          <td align="right">
                            Total Point :{" "}
                              {invoice?.customer?.point
                              ? n_format(invoice?.customer?.point)
                              : n_format(invoice?.order_point)}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
                {invoice?.customer?.phone ? (
                  <span>
                    <table className="w_100">
                      <tbody>
                        <tr>
                          <td>
                            Redeem Point :{" "}
                            {invoice?.redeem_amount
                              ? n_format(invoice?.redeem_amount)
                              : "0.00"}{" "}
                          </td>
                          <td align="right">
                            Balance Point :{" "}
                            {customer?.data?.point
                              ? n_format(
                                  invoice?.customer?.point +
                                    invoice?.order_point -
                                    invoice?.redeem_amount
                                )
                              : n_format(
                                invoice?.order_point - invoice?.redeem_amount
                                )}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div />
          <div className="text_center arial my_3">
            {invoice?.branch?.message || user?.data?.branch?.message}
          </div>
          {invoice?.branch?.wifiname  || user?.data?.branch?.wifiname ? (
            <span>
              <table className="w_100 arial">
                <tbody>
                  <tr>
                    <td>WiFi :{invoice?.branch?.wifiname  || user?.data?.branch?.wifiname}</td>
                    <td align="justify">
                      Password:{invoice?.branch?.wifipass  || user?.data?.branch?.wifipass}
                    </td>
                  </tr>
                </tbody>
              </table>
            </span>
          ) : null}
        </div>

        <div className="pagebreak"></div>
      </>
    );
  };
  return (
    <CommonModal
      full
      children={renderModal}
      show={show}
      onOk={onOk}
      title=""
      ok={false}
      header="Change Information"
      title_2="Closed"
      cancel
      toggleModal={back}
      style={{
        display: show ? "block" : "none",
        visibility:"hidden"
      }}
    />
  );
}

export default InvoiceModal;
