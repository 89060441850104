export const setOrderProducts = (payload) => {
  return {
    type: "SET_ORDER_PRODUCTS",
    payload,
  };
};

export const setSelectedProduct = (payload) => {
  return {
    type: "SET_SELECTED_PRODUCT",
    payload,
  };
};

export const deleteProduct = (payload) => {
  return {
    type: "DELETE_PRODUCT",
    payload,
  };
};

export const emptyOrder = () => {
  return {
    type: "EMPTY_ORDER",
  };
};

export const setDiscount = (payload) => {
  return {
    type: "SET_DISCOUNT",
    payload,
  };
};

export const setCustomer = (payload) => {
  return {
    type: "SET_CUSTOMER",
    payload,
  };
};

export const removeCustomer = (payload) => {
  return {
    type: "REMOVE_CUSTOMER",
    payload,
  };
};

export const setUser = (payload) => {
  return {
    type: "SET_USER",
    payload,
  };
};

export const removeUser = (payload) => {
  return {
    type: "REMOVE_User",
    payload,
  };
};


export const setInvoice = (payload) => {
  return {
    type: "SET_INVOICE",
    payload,
  };
};

export const fillOrder = (payload) => {
  return {
    type: "FILL_ORDER",
    payload,
  };
};

export const toggleTender = () => {
  return {
    type: "TOGGLE_TENDER",
  };
};

export const setPrinted = (payload) => {
  return {
    type: "SET_PRINTED",
    payload
  };
};


export const fillOrders = (payload) => {
  return {
      type: 'FILL_ORDERS',
      payload,
  };
};
