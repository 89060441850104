const initialState = {
	uri_api: 'https://admin.x345.live/api',
	uri: 'https://admin.x345.live/',
	active_category: 4,
	modal_show: false,
	modal_children: <h1>hello</h1>,
	lock: false,
	currencies: [],
	current_currency: 'USD',
	trashActive: false,
	showkeyboard: false,
	text: '',
	enter: false,
	activeField: 'B',
	cache: false,
	cs: false,
  showLoading:false
}

const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY'
const TOGGLE_MODAL = 'TOGGLE_MODAL'
const TOGGLE_LOCK = 'TOGGLE_LOCK'
const SET_MODAL_CHILDREN = 'SET_MODAL_CHILDREN'
const SET_CURRENCIES = 'SET_CURRENCIES'
const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY'
const TOGGLE_TRASH = 'TOGGLE_TRASH'
const TOGGLE_KEYBOARD = 'TOGGLE_KEYBOARD'
const SET_TEXT = 'SET_TEXT'
const EMPTY_TEXT = 'EMPTY_TEXT'
const BACKSPACE_TEXT = 'BACKSPACE_TEXT'
const SET_ACTIVE_FIELD = 'SET_ACTIVE_FIELD'
const TOGGLE_OFF = 'TOGGLE_OFF'
const CLEAR_CACHE = 'CLEAR_CACHE'
const ON_ENTER = 'ON_ENTER'
const SET_CUSTOMER_SCREEN = 'SET_CUSTOMER_SCREEN'
const SET_LOADING = 'SET_LOADING'

const commonReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACTIVE_CATEGORY:
			return {
				...state,
				active_category: action.payload,
			}
		case TOGGLE_MODAL:
			return {
				...state,
				modal_show: !state.modal_show,
			}
		case TOGGLE_LOCK:
			return {
				...state,
				lock: !state.lock,
			}
		case SET_MODAL_CHILDREN:
			return {
				...state,
				modal_children: action.payload,
			}
		case SET_CURRENCIES:
			return {
				...state,
				currencies: action.payload,
			}
		case SET_CURRENT_CURRENCY:
			return {
				...state,
				current_currency: action.payload,
			}
		case TOGGLE_TRASH:
			return {
				...state,
				trashActive: !state.trashActive,
			}
		case TOGGLE_OFF:
			return {
				...state,
				trashActive: false,
			}
		case TOGGLE_KEYBOARD:
			return {
				...state,
				showkeyboard: action.payload ?? !state.showkeyboard,
			}

		case SET_TEXT:
			return {
				...state,
				text: action.payload,
				enter: false,
			}
		case EMPTY_TEXT:
			return {
				...state,
				text: '',
				enter: false,
			}
		case BACKSPACE_TEXT:
			return {
				...state,
				text: state?.text?.toString()?.slice(0, -1),
			}
		case SET_ACTIVE_FIELD:
			return {
				...state,
				activeField: action.payload,
			}
		case ON_ENTER:
			return {
				...state,
				enter: action.payload,
			}
		case CLEAR_CACHE:
			return {
				...state,
				cache: true,
			}
		case SET_CUSTOMER_SCREEN:
			return {
				...state,
				cs: action.payload,
			}
		case SET_LOADING:
			return {
				...state,
				showLoading: action.payload,
			}

		default:
			break
	}
	return state
}

export default commonReducer
