import React from 'react'
import { Motion, spring, presets } from 'react-motion'

const FullKeyboard = () => {
	// JSX
	return (
		<div
			style={{
				zIndex: '2',
				position: 'absolute',
				top: '50%',
				width: '100%',
				display: 'flex',
				alignSelf: 'center',
				margin: 'auto',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
			}}
		>
			<Motion
				defaultStyle={{
					opacity: 0,
					translateY: 20,
				}}
				style={{
					opacity: spring(1),
					translateY: spring(0, presets.gentle),
				}}
			>
				{(interpolatedStyles) => (
					<div
						style={{
							transform: `translateY(${interpolatedStyles.translateY}px)`,
							opacity: interpolatedStyles.opacity,
						}}
					>
						{' '}
						<div
							style={{
								boxShadow: '1px 1px 6px 2px #777',
								padding: '5px 10px',
								display: 'flex',
								alignSelf: 'center',
								margin: 'auto',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row',
								backgroundColor: '#f96f51',
							}}
						>
							<div className="lds-ripple">
								<div></div>
								<div></div>
							</div>
							<div
								style={{
									color: '#fff',
								}} 
							>
								{' '}
								Loading...
							</div>
						</div>
					</div>
				)}
			</Motion>
		</div>
	)
}

export default FullKeyboard
