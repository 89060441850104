import React, { Suspense, useEffect, useState } from "react";
import ButtonSm from "../../../common/components/ButtonSm";
import ButtonLg from "../../../common/components/ButtonLg";
import ButtonMd from "../../../common/components/ButtonMd";
import { FaArrowDown, FaArrowUp, FaPowerOff, FaTrash } from "react-icons/fa";

import { useSelector, useDispatch } from "react-redux";
import { n_format, toggleFullScreen, togglePOSFullScreen } from "../../../utils/common";

import * as commonActions from "../../../state/common/commonActions";
import * as orderActions from "../../../state/order/orderActions";
import * as customerActions from "../../../state/customer/customerActions";
import * as userActions from "../../../state/user/userActions";
import * as suspendActions from "../../../state/suspend/suspendActions";
import * as customerScreenActions from "../../../state/customerScreen/customerScreenActions";
import CommonModal from "../../../common/components/CommonModal";
import NumberBtn from "../../../common/components/NumberBtn";
import AddNewProduct from "./AddNewProduct";
import Tender from "../../TendorPage/";
import Drag from "../../../assets/svgs/Drag";
import Operations from "../../../assets/svgs/Operations";
import Edit from "../../../assets/svgs/Edit";
import ConfirmModal from "./ConfirmModal";
import BottomKeyPad from "./BottomKeyPad";
import InvoiceModal from "./InvoiceModal.jsx";
import { Link, useHistory } from "react-router-dom";
import SuspendModal from "./SuspendModal";

function HomeFooter({ isCustomer }) {
  const order = useSelector((state) => state.order);
  const customer = useSelector((state) => state.customer);
  const suspend = useSelector((state) => state.suspend);

  const [screenState, setScreenState] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const history = useHistory();

  // Discount States
  const [showDiscount, setShowDiscount] = useState(false);
  const [percent, setPercent] = useState("");
  const [all, setAll] = useState(true);
  const [oldPercent, setOldPercent] = useState(0);

  // Tender States
  const [showTender, setShowTender] = useState(false);

  // Operation States
  const [showOperation, setShowOperation] = useState(false);

  // Edit States

  const [showEdit, setShowEdit] = useState(false);

  // Add New States
  const [showAddNew, setShowAddNew] = useState(false);

  // Cancel States
  const [showCancel, setShowCancel] = useState(false);

  // Confirm Popup
  const [showConfirm, setShowConfirm] = useState(false);

  // Invoice Popup

  const [showInvoice, setShowInvoice] = useState(false);

  // Confirm Logout
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);

  // Suspend
  const [suspendTitle, setSuspendTitle] = useState("Suspend");
  const [showSuspend, setShowSuspend] = useState(false);

  const toggleSuspend = () => {
    setShowSuspend(!showSuspend);
  };

  function openWindow() {
    var width = 5550;
    var left = 5600;
    var w = 5550;
    var h = 5600;
    var top = window.screen.height / 2 - h / 2;
    var url = "customer-screen";
    var title = "Customer Screen";

    left += Math.abs(window.screenX);

    console.log(window);

    window.open(
      "/?screen=cs",
      "windowName",
      "resizable=1,scrollbars=1,fullscreen=0,height=100%,width=" +
        width +
        " , height=" +
        h +
        " , top=" +
        top +
        ", left=" +
        left +
        ", toolbar=0, menubar=0,status=1"
    );
    return 0;
  }

  function multipleScreenPopup(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft =
      window.screenLeft != undefined ? window.screenLeft : window.screen.left;
    var dualScreenTop =
      window.screenTop != undefined ? window.screenTop : window.screen.top;

    var width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    var height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    var left = width / 2 - w / 2 + dualScreenLeft;
    var top = height / 2 - h / 2 + dualScreenTop;
    var newWindow = window.open(
      url,
      title,
      "scrollbars=yes, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus();
    }
  }

  const onOkConfirmLogout = () => {
    dispatch(customerScreenActions.toggleScreen(false));
    dispatch(commonActions.trashOff());
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    dispatch(userActions.emptyUser());
    dispatch(suspendActions.emptySuspend());
    // togglePOSFullScreen();
    //toggleFullScreen(); 
    history.push("/");
  };

  const toggleInvoice = (toggleConfirmParam = () => {}) => {
    setShowInvoice(!showInvoice);
    toggleConfirmParam && toggleConfirmParam();
  };

  const toggleConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const dispatch = useDispatch();

  let pureTotalAmount = 0.0;
  let pureQuantity = 0.0;
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalQuantity, setTotalQuantity] = useState(0.0);
  const [changedAmount, setChangedAmount] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);

  useEffect(() => {
    setOrderAmount();
    if (order.products.length === 0) {
      dispatch(commonActions.trashOff());
    }
  }, [order.products]);

  const setOrderAmount = () => {
    pureQuantity = 0.0;
    pureTotalAmount = 0.0;
    order.products.forEach((e) => {
      const oldTotal = n_format(e?.quantity) * n_format(e?.price);
      const discount =
        (n_format(oldTotal) * n_format(e?.discount_percent)) / 100;
      const total = n_format(oldTotal) - n_format(discount);
      pureTotalAmount = +pureTotalAmount + +total;
      // totalDiscount = totalDiscount + discount;
      pureQuantity = +pureQuantity + +e?.quantity;
    });
    const discount =
      (n_format(order.discount) * n_format(pureTotalAmount)) / 100;
    setOrderDiscount(discount);
    setTotalAmount(pureTotalAmount);
    setTotalQuantity(pureQuantity);
  };

  const onOk = () => {
    if (all) {
      console.log(percent, "inside Ok");
      dispatch(orderActions.setDiscount(percent));
    } else {
      dispatch(orderActions.setOrderProducts(order.selected_product_item));
    }
    setDiscount(true);
  };

  const changePercent = (num) => {
    setPercent(percent + "" + num);
  };

  const changePercentDelete = (allDelete) => {
    if (allDelete) {
      setPercent("");
      setChangedAmount("0.00");
    } else {
      setPercent(percent.slice(0, -1));
    }
  };
  const changeCalculate = (perc) => {
    if (all) {
      if (perc) {
        setPercent(perc);
        setChangedAmount((n_format(totalAmount) * n_format(perc)) / 100);
      } else {
        // if (n_format(percent) > 0)
        setChangedAmount((n_format(totalAmount) * n_format(percent)) / 100);
      }
    } else {
      if (perc) {
        console.log(perc, "1");
        dispatch(
          orderActions.setSelectedProduct({
            ...order.selected_product_item,
            discount_percent: n_format(perc),
          })
        );
        console.log(order.selected_product_item?.discount_percent, "2");
        setPercent(perc);
        console.log(percent, "3");
      } else {
        console.log(percent, "4");
        dispatch(
          orderActions.setSelectedProduct({
            ...order.selected_product_item,
            discount_percent: n_format(percent),
          })
        );
        console.log(order.selected_product_item?.discount_percent, "5");
      }
    }
  };

  useEffect(() => {
    setOrderAmount();
    changeCalculate();
  }, [all, percent]);

  const renderModal = () => {
    let selectedTotal = 0;
    // if (n_format(order.selected_product_item?.discount_percent) > 0) {
    const pureTotal =
      n_format(order.selected_product_item?.price) *
      n_format(order.selected_product_item?.quantity);
    const selectedDiscount =
      (n_format(order.selected_product_item?.discount_percent) * pureTotal) /
      100;
    selectedTotal = pureTotal - selectedDiscount;
    // }
    return (
      <div className="w-100">
        <div className="column justify-center w-100">
          <div className="row w-100 justify-end ">
            <div
              className={`modal-btn bg-1 text-1 ${all ? "active-scale" : ""}`}
              onClick={() => setAll(true)}
            >
              Invoice
            </div>
            <div
              className={`modal-btn bg-3 text-1 ${!all ? "active-scale" : ""}`}
              onClick={() => setAll(false)}
            >
              Item
            </div>
          </div>
        </div>
        <div className="w-100 row align-right justify-between">
          <div className="">
            <div
              className="modal-btn bg-6 text-1"
              onClick={() => changeCalculate("0.00")}
            >
              0%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(10)}
            >
              10%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(20)}
            >
              20%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(30)}
            >
              30%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(40)}
            >
              40%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(50)}
            >
              50%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(60)}
            >
              60%
            </div>
            <div
              className="modal-btn bg-1 text-1"
              onClick={() => changeCalculate(70)}
            >
              70%
            </div>
          </div>
          <div className="numpad-grid mt-1">
            <div className="column w-100 align-right ">
              <div className="justify-between w-100 row">
                <span
                  className="text-1 "
                  style={{ fontSize: 13, textAlign: "left" }}
                >
                  Amount
                </span>
                <input
                  type="text"
                  value={
                    !all
                      ? n_format(selectedTotal || 0)
                      : n_format(changedAmount)
                  }
                  readOnly
                  className="border-bg-1 numpad-input"
                />
              </div>
              <div className="justify-between w-100 row">
                <span
                  className="text-1 "
                  style={{ fontSize: 13, textAlign: "left" }}
                >
                  Percentage
                </span>
                <input
                  type="text"
                  className="border-bg-3 numpad-input"
                  value={percent}
                  autoFocus={true}
                  style={{
                    background: "rgba(0,0,250,0.41)",
                    color: "#fff",
                    padding: 5,
                  }}
                />
              </div>
            </div>
            <div className="row justify-end">
              <NumberBtn onClick={() => changePercent(1)} title={1} />
              <NumberBtn onClick={() => changePercent(2)} title={2} />
              <NumberBtn onClick={() => changePercent(3)} title={3} />
              <NumberBtn
                onClick={() => changePercentDelete(true)}
                fontSize={12}
                title={"Delete"}
              />
            </div>
            <div className="row justify-end">
              <NumberBtn onClick={() => changePercent(4)} title={4} />
              <NumberBtn onClick={() => changePercent(5)} title={5} />
              <NumberBtn onClick={() => changePercent(6)} title={6} />
              <NumberBtn
                onClick={() => changePercentDelete(false)}
                fontSize={12}
                title={"Back space"}
              />
            </div>
            <div className="row justify-end">
              <div className="">
                <div className="row">
                  <NumberBtn onClick={() => changePercent(7)} title={7} />
                  <NumberBtn onClick={() => changePercent(8)} title={8} />
                  <NumberBtn onClick={() => changePercent(9)} title={9} />
                </div>
                <div className="row justify-end">
                  <NumberBtn onClick={() => changePercent(0)} title={0} />
                  <NumberBtn onClick={() => changePercent(".")} title={"."} />
                  <NumberBtn
                    onClick={() => changePercentDelete(true)}
                    fontSize={12}
                    title={"Clear"}
                  />
                </div>
              </div>
              <div className="">
                <NumberBtn
                  onClick={() => changeCalculate()}
                  down
                  fontSize={12}
                  title={"Enter"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const setQuanity = () => {
    if (order.products.length > 0) {
      dispatch(commonActions.toggleModal());
    }
  };

  const setDiscount = (save = false) => {
    if (order.products.length > 0) {
      if (save) {
        // setOldPercent(0);
        // setPercent("");
        setPercent("");
        setAll(true);

        setShowDiscount(!showDiscount);
      } else {
        if (showDiscount) {
          setChangedAmount(0.0);
          setPercent("");
          dispatch(
            orderActions.setSelectedProduct({
              ...order.selected_product_item,
              discount_percent: n_format(oldPercent),
            })
          );
        } else {
          setChangedAmount(0.0);
          setOldPercent(
            n_format(order.selected_product_item?.discount_percent)
          );
          setPercent("");
          changePercent(
            // n_format(order.selected_product_item?.discount_percent) > 0 ?
            n_format(order.selected_product_item?.discount_percent)
            // : ""
          );

          setAll(true);
        }
        setShowDiscount(!showDiscount);
      }
    }
  };

  useEffect(() => {
    if (suspend?.data?.length > 0) setSuspendTitle("Load Pending");
    else setSuspendTitle("Suspend");
  }, [suspend]);

  const onScreenStateChange = () => {
    if (order?.products?.length > 0) {
      console.log("here");
      dispatch(
        suspendActions.addSuspend({
          customer,
          order,
          id: Math.floor(Math.random() * 90000) + 10000,
        })
      );
      dispatch(commonActions.toggleTrash());
      dispatch(orderActions.emptyOrder());
      dispatch(customerActions.emptyCustomer());
    } else if (suspend?.data?.length > 0) {
      setShowSuspend(!showSuspend);
    }
    // dispatch(commonActions.toggleLock());
    // setScreenState(!screenState);
  };

  const scrollDown = () => {
    let index = 0;
    order.products.forEach((e, i) => {
      if (
        order.selected_product === e.barcode &&
        i < order.products.length - 1
      ) {
        index = i + 1;
        return;
      }
    });
    dispatch(orderActions.setSelectedProduct(order.products[index]));
  };

  const scrollUp = () => {
    let index = 0;
    order.products.forEach((e, i) => {
      if (order.selected_product === e.barcode && i > 0) {
        index = i - 1;
        return;
      }
    });
    dispatch(orderActions.setSelectedProduct(order.products[index]));
  };

  const deleteProduct = () => {
    dispatch(commonActions.toggleTrash());
  };

  // Tender Modal

  const toggleTender = (saved) => {
    let letTendor = true;
    if (order?.products?.length > 0) {
      order?.products?.forEach(e => {
        if(!(e?.price > 0)){
          letTendor = false;
          return;
        }
      });
    } else{
      letTendor = false;
    }
    if(letTendor)
     setShowTender(!showTender);
  };

  // Operation Modal

  const renderOperation = () => {
    return <Operations onClick={toggleOperation} />;
  };

  const toggleOperation = () => setShowOperation(!showOperation);

  // Cancel Modal

  const toggleCancel = () => setShowCancel(!showCancel);
  const toggleConfirmLogout = () => setShowConfirmLogout(!showConfirmLogout);

  const onOkCancel = () => {
    dispatch(commonActions.toggleTrash());
    dispatch(orderActions.emptyOrder());
    dispatch(customerActions.emptyCustomer());
    setShowCancel(false);
  };

  // Add New

  const toggleAddNew = () => {
    setShowAddNew(!showAddNew);
  };

  // Edit

  const renderEdit = () => {
    return <Edit onClick={toggleEdit} />;
  };

  const toggleEdit = () => setShowEdit(!showEdit);

  return (
    <>
      <CommonModal
        children={renderModal}
        show={showDiscount}
        onOk={onOk}
        title="Discount"
        toggleModal={() => setDiscount()}
      />

      <CommonModal
        children={() => {}}
        show={showCancel}
        onOk={onOkCancel}
        title="Cancel"
        toggleModal={toggleCancel}
      />

      <SuspendModal toggle={toggleSuspend} show={showSuspend} />

      <CommonModal
        children={() => {}}
        show={showConfirmLogout}
        onOk={onOkConfirmLogout}
        title="Confirm Logout ?"
        toggleModal={toggleConfirmLogout}
      />

      <CommonModal full children={renderOperation} show={showOperation} />
      <CommonModal full children={renderEdit} show={showEdit} />

      <AddNewProduct toggle={toggleAddNew} show={showAddNew} />
      <ConfirmModal
        toggle={toggleConfirm}
        toggleInvoice={toggleInvoice}
        show={showConfirm}
      />
      <InvoiceModal toggle={toggleInvoice} show={showInvoice} />
      <Tender
        show={showTender}
        toggleConfirm={toggleConfirm}
        toggleInvoice={toggleInvoice}
        totalAmount={n_format(totalAmount)}
        totalDiscount={n_format(orderDiscount)}
        toggle={toggleTender}
      />
      <div className="home-footer">
        <BottomKeyPad />
        <div className="row">
          {!isCustomer ? (
            <ButtonMd
              title="Delete"
              onClick={deleteProduct}
              icon={<FaTrash color="#fff" />}
            />
          ) : null}
          {!isCustomer ? (
            <ButtonSm
              title="Scroll Down"
              onClick={scrollDown}
              icon={<FaArrowDown color="#fff" />}
            />
          ) : null}
          {!isCustomer ? (
            <ButtonSm
              title="Scroll Up"
              onClick={scrollUp}
              icon={<FaArrowUp color="#fff" />}
            />
          ) : null}
          <ButtonLg
            bg="bg-5"
            text="text-2"
            title="Total Qty"
            value={totalQuantity}
          />
          <ButtonLg
            bg="bg-5"
            text="text-2"
            title="Sub Total"
            value={"$" + n_format(totalAmount)}
          />
          <ButtonLg
            bg="bg-5"
            text="text-2"
            title="Discount"
            value={n_format(orderDiscount)}
          />
          <ButtonLg
            bg="bg-6"
            title="Grand Total"
            value={"$" + n_format(totalAmount - n_format(orderDiscount))}
          />
        </div>
        {!isCustomer ? (
          <div className="row">
            <ButtonMd
              bg="bg-9"
              title="Qty"
              onClick={setQuanity}
              value={order.products.length}
            />
            <ButtonMd bg="bg-1" title="Price" onClick={toggleAddNew} />
            <ButtonMd
              bg="bg-8"
              title="Discount"
              onClick={() => setDiscount()}
              value={order.products.length}
            />
            <Link className="btn" to="reprint">
              {" "}
              <ButtonMd bg="bg-7" title="Reprint" />{" "}
            </Link>
            {/* <ButtonMd
              title="Customer Screen"
              onClick={() =>
                //multipleScreenPopup("customer-screen", "_blank", 500, 500)
                openWindow()
              }
            /> */}
            <ButtonMd
              title="Operation"
              onClick={() => history.push("/operation")}
            />
            {/* <ButtonMd
              bg="bg-7"
              title="Customer Screen"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => history.push("/?screen=cs")}
            /> */}
            <ButtonMd
              bg="bg-8"
              title={suspendTitle}
              style={{ zIndex: 1 }}
              onClick={onScreenStateChange}
            />
            <ButtonMd bg="bg-1" title="Cancel" onClick={toggleCancel} />
            <ButtonMd bg="bg-9" title="Tender" onClick={toggleTender} />
          </div>
        ) : null}
        <div className="row">
          <div
            className={`btn-md btn row t-center justify-between align-center bg-1`}
            style={{ height: 25, paddingRight: 10, paddingLeft: 10 }}
          >
            <Drag />
            <Drag />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeFooter;
