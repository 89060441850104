import OrderService from "../../api/OrderAPI";

const invoiceAPIs = {
  addOrder: async (data) => {
    console.log(data, "branchIdunu");
    const response = await OrderService.addOrder({ ...data.data });
    return response;
  }
};

export default invoiceAPIs;