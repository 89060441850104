import React from "react";
import TableRow from "./TableRow";

function ProductsTable({ isCustomer, invoices = [], selected, setSelected, isLoading }) {
  return (
    <>
      <div className="w-100  justify-between row ">
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Invoice No.</span>
          </div>
        </div>
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 ">Date</span>
          </div>
        </div>

        <div className="w-30">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Total Amount($)</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Save Point</span>
          </div>
        </div>

        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Paid Point</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Customer Name</span>
          </div>
        </div>
      </div>
      {isLoading ?
        <div className="row justify-center bg-1 align-center">
          <div className="lds-ripple"><div></div><div></div></div>
        </div>
        : null}
      <div className="product-table-scroll" style={{ maxHeight: "60vh" }}>

        {invoices?.length > 0 ? invoices.map((e) => {
          return <TableRow e={e} selected={selected} setSelected={setSelected} />;
        })
          :
          !isLoading &&
          <h2 className="row justify-center align-center mt-1 mb-1">No Invoice Found</h2>
          }
      </div>
      <div className="w-100  justify-between row">
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>

        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsTable;
