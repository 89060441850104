import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TableRow from "./TableRow";


function ProductsTable({ isCustomer,invoices=[],selected,setSelected,setReturnInvoice, partialInvoiceReturn,isLoading }) {
  const order = useSelector((state) => state.order);
  const products = useSelector((state) => state.products);
  const invoice = useSelector((state) => state.invoice);
  const active_category = useSelector((state) => state.common.active_category);

 


  

  return (
    <>
      <div className="w-100  justify-between row">
      <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Invoice No.</span>
          </div>
        </div>

        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 ">Date</span>
          </div>
        </div>
       
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Total Amount($)</span>
          </div>
        </div>
        
      
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Save Point</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Paid Point</span>
          </div>
        </div>
       
       
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Action</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Return</span>
          </div>
        </div>
      </div>
      {isLoading?
      <div className="row justify-center bg-1 align-center">
        <div  className="lds-ripple"><div></div><div></div></div> 
      </div>
      :null}
      <div className="product-table-scroll" style={{maxHeight:"60vh"}}>
{invoices.map(e => {
    return (
 <TableRow e={e}  partialInvoiceReturn={ partialInvoiceReturn} selected={selected} setSelected={setSelected} setReturnInvoice={setReturnInvoice} />
             
    )
})}
</div>
 <div className="w-100  justify-between row">
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
       
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsTable;
