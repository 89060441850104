  
  export const addUser = (payload) => {
      
    return {
      type: "ADD_USER",
      payload,
    };
  };
  
  export const emptyUser = (payload) => {
    return {
    type: "EMPTY_USER",
      payload,
    };
  };