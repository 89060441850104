import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonLg from "../../../common/components/ButtonLg";
import Motion1 from "../../../common/components/Motion1";
import * as commonActions from "../../../state/common/commonActions";

function BottomKeyPad({ show, handleClick, handleDelete, toggle, setItem }) {
  const common = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(commonActions.toggleKeyboard());
  };

  const onKeyPress = (text) => {
    dispatch(commonActions.setText(common?.text + "" + text));
  };

  const onDelete = () => {
    dispatch(commonActions.emptyText());
  };

  const onBackSpace = () => {
    dispatch(commonActions.backspaceText());
  };

  const onEnter = () => {
    dispatch(commonActions.onEnter(true));
  };

  return common?.showkeyboard ? (
    <Motion1>
      <div className="row bottom-keyboard">
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="1"
          onClick={() => onKeyPress(1)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="2"
          onClick={() => onKeyPress(2)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="3"
          onClick={() => onKeyPress(3)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="4"
          onClick={() => onKeyPress(4)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="5"
          onClick={() => onKeyPress(5)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="6"
          onClick={() => onKeyPress(6)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="7"
          onClick={() => onKeyPress(7)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="8"
          onClick={() => onKeyPress(8)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="9"
          onClick={() => onKeyPress(9)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="0"
          onClick={() => onKeyPress(0)}
        />
        <ButtonLg
          bg="bg-1"
          text="text-1"
          title="."
          onClick={() => onKeyPress(".")}
        />
        <ButtonLg bg="bg-1" text="text-1" title="Enter" onClick={onEnter} />
        <ButtonLg bg="bg-1" text="text-1" title="Clear" onClick={onDelete} />
        <ButtonLg bg="bg-1" text="text-1" title="Bksp" onClick={onBackSpace} />
        <ButtonLg bg="bg-1" text="text-1" title="X" onClick={onClick} />
      </div>
    </Motion1>
  ) : (
    <></>
  );
}

export default BottomKeyPad;
