import React from "react";

function ModalHeader({
  title,
  handleCancel,
  handleOk,
  handleBack,
  title_1 = "Ok",
  title_2 = "Cancel",
  title_3 ="Back",
  ok = true,
  cancel = true,
  back = false,
  isSaving= false
}) {
  return (
    <div className="modal-header column align-center justify-center">
      <h2>{title}</h2>
      <div className="modal-actions row justify-center">
        {ok ? (
          <div className="modal-btn bg-3 text-1" onClick={handleOk}>
            {title_1}
          </div>
        ) : null}
        {cancel ? (
          <div className={`modal-btn ${isSaving ? 'bg-5':'bg-1'}  text-1`} onClick={handleCancel}>
            {title_2}
          </div>
        ) : null}
        {back ? (
          <div className="modal-btn bg-1 text-1" onClick={handleBack}>
            {title_3}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ModalHeader;
