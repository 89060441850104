import React from "react";
import { Motion, spring, presets } from "react-motion";

function Motion1({ children }) {
  return (
    <Motion
      defaultStyle={{
        opacity: 0,
        translateY: 50,
      }}
      style={{
        opacity: spring(1),
        translateY: spring(0, presets.wobbly),
      }}
    >
      {(interpolatedStyles) => (
        <div
         
          style={{
            transform: `translateY(${interpolatedStyles.translateY}px)`,
            opacity: interpolatedStyles.opacity,
          }}
        >
          {children}
        </div>
      )}
    </Motion>
  );
}

export default Motion1;
