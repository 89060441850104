import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { n_format } from "../../../utils/common";


function FinalTable({ data }) {




  return (
    <>
      <div className="w-100  justify-between row">
      {/* <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Invoice No.</span>
          </div>
        </div> */}

        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 ">Start Date</span>
          </div>
        </div>
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 ">End ate</span>
          </div>
        </div>
       
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Total Amount($)</span>
          </div>
        </div>

        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 ">Return Amount($)</span>
          </div>
        </div>
      
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Save Point</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Paid Point</span>
          </div>
        </div>
       
       
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Discount</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 ">Net Amount</span>
          </div>
        </div>
      </div>
      <div className="w-100  justify-between row">
        {/* <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-6">
            <span className="text-1 "></span>
          </div>
        </div> */}
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-5">
            <span className="text-3 " style={{fontSize:20}}>{data?.startDate?.toString()}</span>
          </div>
        </div>
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-5">
            <span className="text-3 " style={{fontSize:20}}>{data?.endDate?.toString()}</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-5">
            <span className="text-3 " style={{fontSize:20}}>$ {n_format(data?.amount)}</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-5">
            <span className="text-3 " style={{fontSize:20}}>$ {n_format(data?.ramount)}</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-5">
            <span className="text-3 " style={{fontSize:20}}>{n_format(data?.points)}</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-5">
            <span className="text-3 " style={{fontSize:20}}>{n_format(data?.redeemedPoints ?? 0)}</span>
          </div>
        </div>
       
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-5">
            <span className="text-3 " style={{fontSize:20}}>$ {n_format(data?.discount)}</span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-5">
            <span className="text-3 " style={{fontSize:20}}>$ {n_format(+data?.amount - +data?.discount)}</span>
          </div>
        </div>
      </div>
   
 <div className="w-100  justify-between row">
        {/* <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div> */}
        <div className="w-100">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4  bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
       
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
        <div className="w-60">
          <div className="btn t-header row justify-center align-center t-center  border-bg-4 bg-1">
            <span className="text-1 "></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinalTable;
