export const searchCustomer = (payload) => {
    return {
      type: "SEARCH_CUSTOMER",
      payload,
    };
  };
  
  export const addCustomer = (payload) => {
    return {
      type: "ADD_CUSTOMER",
      payload,
    };
  };
  
  export const emptyCustomer = (payload) => {
    return {
    type: "EMPTY_CUSTOMER",
      payload,
    };
  };

  export const fillCustomer = (payload) => {
    return {
    type: "FILL_CUSTOMER",
      payload,
    };
  };