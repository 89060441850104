const initialState = {
  data: [],
  activeInvoice: {},
  lastInvoiceId:null,
  lastRInvoiceId:null,
};

const FILL_INVOICES = "FILL_INVOICES";
const EMPTY_INVOICES = "EMPTY_INVOICES";
const SET_ACTIVE_INVOICE = "SET_ACTIVE_INVOICE";
const SET_LAST_INVOICE_ID = "SET_LAST_INVOICE_ID";
const SET_LAST_R_INVOICE_ID = "SET_LAST_R_INVOICE_ID";

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILL_INVOICES:
      return {
        ...state,
        data: action.payload,
      };
    case SET_ACTIVE_INVOICE:
      return {
        ...state,
        activeInvoice: action.payload,
      };
    case EMPTY_INVOICES:
      return {
        ...state,
        data: [],
      };
    case SET_LAST_INVOICE_ID:
      return {
        ...state,
        lastInvoiceId: action.payload,
      };
    case SET_LAST_R_INVOICE_ID:
        return {
          ...state,
          lastRInvoiceId: action.payload,
        };
    default:
      break;
  }
  return state;
};

export default userReducer;
